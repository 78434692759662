import { Injectable } from '@angular/core';
import { StorageService } from '@optimo/core';
import decode from 'jwt-decode';
import { EOptimoProductType } from 'libs/core/src/lib/models/EOptimoProductType';
import { PackageType } from 'libs/core/src/lib/models/package-type.enum';
import { Providers } from '../../enums/providers.enum';

const roleKey = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

interface IAccessToken {
  BusinessType: number[];
  LegalEntityId: string;
  LegalEntityName: string;
  PackageType: PackageType;
  ProductType: EOptimoProductType;
  TokenType: number;
  UserType: number;
  data: string;
  exp: number;
  fullName: string;
  [roleKey]: string;
  ['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']: string;
  ['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']: string;
  iat: number;
  integrationProviders: Providers[];
  isAdapter: boolean;
  isForTesting: boolean;
  iss: string;
  iv: string;
  permissions?: string;
  sub: string;
  taxRate: number;
  uid: string;
  isAdmin?: boolean;
  legalEntityStatusName?: string;
  hasFiscalIntegration?: any;
}

interface IConfig {
  hasFiscalIntegration?: boolean;
  isGlovoUser: boolean;
  isHorecaMode: boolean;
  userRole: string;
  isAdmin: boolean;
  packageType: PackageType;
  isAdapter: boolean;
  productType: EOptimoProductType;
  businessType: number[];
  legalEntityId: string;
  legalEntityName: string;
  integrationProviders: Providers[];
  isForTesting: boolean;
  taxRate: number;
  fullName: string;
  permissions: IPermission[];
  legalEntityStatusName: string;
  userUid: string;
}

interface IPermission {
  locationId: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private _storageService: StorageService) {}

  public getConfig(): IConfig {
    const accessToken = this._storageService.getAccessToken();
    if (!accessToken) return;
    const tokenPayload: IAccessToken = decode(accessToken);
    const {
      isAdmin,
      PackageType,
      isAdapter,
      ProductType,
      BusinessType,
      LegalEntityId,
      LegalEntityName,
      integrationProviders,
      isForTesting,
      taxRate,
      fullName,
      permissions,
      sub,
      hasFiscalIntegration,
    } = tokenPayload;
    const _config: IConfig = {
      isGlovoUser: !!tokenPayload.integrationProviders?.find(
        (el) => el === Providers.Glovo,
      ),
      isAdmin: !!isAdmin,
      packageType: PackageType,
      isAdapter,
      productType: ProductType,
      businessType: BusinessType,
      legalEntityId: LegalEntityId,
      legalEntityName: LegalEntityName,
      integrationProviders,
      isForTesting,
      taxRate,
      fullName,
      permissions: permissions ? JSON.parse(permissions) : [],
      userRole: tokenPayload[roleKey],
      isHorecaMode: ProductType === EOptimoProductType.HORECA,
      legalEntityStatusName: tokenPayload.legalEntityStatusName,
      userUid: sub,
      hasFiscalIntegration: !!hasFiscalIntegration,
    };
    return _config;
  }
}
