import { Observable } from 'rxjs';
export enum ColumnType {
  Number,
  Text,
  Date,
  Select,
  Dropdown,
  Actions,
  Index,
  DropdownMultiselect,
  DateMonth,
}

export interface ColumnData {
  dataField: string;
  columnType: ColumnType;
  caption: string;
  asyncCaption?: Observable<string>;
  filterable: boolean;
  sortable: boolean;
  removeForUZ?: boolean;
  isUzProject?: boolean;
  editable?: boolean;
  data?: any;
  widthCoefficient?: number;
  widthPercent?: number;
  hidden?: boolean;
  canNotChangeVisibility?: boolean;
  hideInVisibilitySelector?: boolean;
  className?: string;
  minStock?: boolean;
  multiselectButton?: boolean;
  hasCurrencySwitcher?: boolean;
  switcherName?: string;
  hasCurrencyLabel?: boolean;
}

export interface SelectionData {
  selected: any[];
  isAllSelected: boolean;
}
