import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  OnInit,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { UserDetails } from '../../../pages/auth/profile/personal-information/profile-personal-information.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageTitleService } from '@optimo/core';
import { DOCUMENT } from '@angular/common';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { Router } from '@angular/router';
import { BillingPaymentServiceService } from '../../../core/services/billing-payment/billing-payment-service.service';
import { LimitServiceService } from '../../../core/services/limit-service/limit-service.service';

@Component({
  selector: 'app-headbar',
  templateUrl: './headbar.component.html',
  styleUrls: ['./headbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeadbarComponent implements OnInit {
  unsubscribe$ = new Subject<void>();

  title = 'abcd';
  titleID = '';

  scrolledToTop = true;

  statisticPages: string[] = [
    '/statistics/general',
    '/statistics/products',
    '/statistics/categories',
  ];

  constructor(
    private router: Router,
    private titleService: PageTitleService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    public notificator: NotificationsService,
    private limitServiceService: LimitServiceService,
    private billingPayService: BillingPaymentServiceService,
  ) {
    // Send new request every time new log in
    this.billingPayService.legalEntitiesBillingInfo();
    this.limitServiceService.checkLimit();
  }

  @Input()
  sidebarComponent: SidebarComponent;

  @Input()
  userDetails: UserDetails;

  @Output()
  logout = new EventEmitter();

  ngOnInit() {
    this.titleService.titleObserver
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((title) => {
        this.title = title;
        const url = this.router.url;
        this.titleID = url.slice(
          url.indexOf('/'),
          url.indexOf('?') === -1 ? url.length : url.indexOf('?'),
        );
        this.cdr.markForCheck();
      });
  }
}
