import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { ColumnTemplate } from '../column-template';
import { DOCUMENT } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';

import { textIsTruncated } from '../../../utils/text-is-truncated';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dropdown-multiselect-column',
  templateUrl: './dropdown-multiselect-column.component.html',
  styleUrls: ['./dropdown-multiselect-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMultiselectComponent
  extends ColumnTemplate
  implements OnInit
{
  isOpen = false;
  filterArray;
  public textIsTruncated = textIsTruncated;
  objectKeys = Object.keys;
  filterForm = this.fb.group({ filter: [''] });
  @Input()
  dropdownKeyForStaticValueSelector: string;

  get selectedKey(): string {
    return this.filterForm.controls.filter.value;
  }

  constructor(
    @Inject(DOCUMENT) document: any,
    cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
  ) {
    super(document, cdr);
  }

  ngOnInit(): void {
    this.listenCheckFilterChange(() => {
      this.changeFilter.emit({
        [this.col.dataField]: this.filterForm.value.filter,
      });
    });
  }

  getStaticPermissionsData(col: any, row: any) {
    if (
      this.dropdownKeyForStaticValueSelector &&
      this.dropdownKeyForStaticValueSelector === col.dataField
    ) {
      return row[this.dropdownKeyForStaticValueSelector];
    } else {
      return col.data[row[col.dataField]];
    }
  }

  protected updateForm(state: {
    [key: string]: string | number | number[] | string[];
  }): void {
    this.filterArray =
      typeof state[this.col.dataField] === 'undefined'
        ? []
        : Array.isArray(state[this.col.dataField])
        ? state[this.col.dataField]
        : [state[this.col.dataField]];
    const filterArray = this.col.multiselectButton
      ? JSON.parse(JSON.stringify(this.filterArray))
      : this.filterArray;
    this.filterForm.patchValue({ filter: filterArray }, { emitEvent: false });
  }

  get isDisabled() {
    const filerValue = this.filterForm.getRawValue()?.filter;
    if (!filerValue) {
      return false;
    }
    const newValue = filerValue.join('');
    const oldValue = this.filterArray.length ? this.filterArray.join('') : '';
    return !this.filterForm.dirty || newValue === oldValue;
  }

  clickOutside() {
    if (this.col.multiselectButton) {
      this.updateForm({ [this.col.dataField]: this.filterArray });
    }
    this.onToggleFilter();
  }

  onSelectionChange(key: string): void {
    let selected: string[] = this.filterForm.getRawValue().filter;
    if (!selected) {
      selected = [];
    }
    const indexOfKey = selected?.indexOf(key);
    if (indexOfKey && indexOfKey === -1) {
      selected.push(key);
    } else {
      selected.splice(indexOfKey, 1);
    }
    this.filterForm.setValue({
      filter: [...selected],
    });
    this.filterForm.markAsDirty();
    if (!this.col.multiselectButton) {
      this.checkFilterChange.next();
    }
  }

  emitChange() {
    if (this.filterForm.dirty) {
      this.filterForm.markAsPristine();
      this.onToggleFilter();
      this.checkFilterChange.next();
    }
  }

  isItemSelected(key: string) {
    return this.filterForm
      .getRawValue()
      ?.filter?.find(
        (value: string | number) => value && value.toString() === key,
      );
  }

  get selectedValues(): string[] {
    return (this?.filterForm?.getRawValue()?.filter || [''])?.map((key) => {
      if (key) {
        const translatedItem = this.translate.instant(this.col.data[key]);
        if (translatedItem !== undefined && key) {
          return translatedItem;
        } else {
          return this.col.data[key];
        }
      } else {
        return this.col.data[key];
      }
    });
  }

  get selectedValuesLabel(): string {
    return this.selectedValues?.join(', ') || '';
  }

  get filterSelected(): boolean {
    return this.filterForm?.getRawValue()?.filter?.length;
  }
  onReset() {
    this.filterForm.reset();
    this.updateForm({ [this.col.dataField]: [] });
    this.onSubmit(true);
  }
}
