import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  ChangeDetectorRef,
  Optional,
} from '@angular/core';
import { DOCUMENT, formatNumber } from '@angular/common';
import { ColumnTemplate } from '../column-template';
import { UntypedFormBuilder } from '@angular/forms';
import { textIsTruncated } from '../../../utils/text-is-truncated';
import { TranslateService } from '@ngx-translate/core';

export enum MeasurementUnit {
  Piece = 1,
  Kilogram,
  Liter,
  Meter,
  SquareMeter,
  Gram,
  Milliliter,
}

export function getUMOAcronym(UMO: MeasurementUnit): string {
  switch (UMO) {
    case MeasurementUnit.Piece: {
      return 'MEASUREMENT_UNITS.PIECE_SHORT';
    }
    case MeasurementUnit.Kilogram: {
      return 'MEASUREMENT_UNITS.KILOGRAM_SHORT';
    }
    case MeasurementUnit.Liter: {
      return 'MEASUREMENT_UNITS.LITER_SHORT';
    }
    case MeasurementUnit.Meter: {
      return 'MEASUREMENT_UNITS.METER_SHORT';
    }
    case MeasurementUnit.SquareMeter: {
      return 'MEASUREMENT_UNITS.SQUAREMETER_SHORT';
    }
    case MeasurementUnit.Gram: {
      return 'MEASUREMENT_UNITS.GRAM_SHORT';
    }
    case MeasurementUnit.Milliliter: {
      return 'MEASUREMENT_UNITS.MILLILITER_SHORT';
    }
    default:
      return '';
  }
}

export enum NumberColumnType {
  Float,
  Percent,
  Decimal,
  Quantity,
  Currency,
  FullNumber,
}

interface Data {
  type: NumberColumnType;
  statusFieldName?: string;
  UOMFieldName?: string;
  isHeaderRight?: boolean;
  prefix?: string;
  digitsAfterDot?: number;
  styleContent?: (row: any) => string[];
}

@Component({
  selector: 'app-number-column',
  templateUrl: './number-column.component.html',
  styleUrls: ['./number-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberColumnComponent extends ColumnTemplate implements OnInit {
  public textIsTruncated = textIsTruncated;
  isOpen = false;
  get data(): Data {
    const def = {
      type: NumberColumnType.Float,
      isHeaderRight: !this.col.sortable,
    };

    if (this.col.data) {
      return {
        ...def,
        ...this.col.data,
      };
    }

    return def;
  }

  displayedFilter = '';
  numberColumnType = NumberColumnType;

  filterForm = this.fb.group({
    from: [''],
    to: [''],
    getLowStockOnly: [''],
  });

  constructor(
    @Optional()
    @Inject('UI_CONFIG')
    public UI_CONFIG: { currencyLabel: string; currencyFormat: string },
    @Inject(DOCUMENT) document: any,
    cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    super(document, cdr);
    if (!this.UI_CONFIG) {
      this.UI_CONFIG = { currencyLabel: '₾', currencyFormat: '1.4-4' };
    }
  }

  public lowStock: boolean;

  ngOnInit(): void {
    this.listenCheckFilterChange(() => {
      if (this.col.minStock) {
        this.changeFilter.emit({
          [`${this.col.dataField}From`]: this.filterForm.value.from,
          [`${this.col.dataField}To`]: this.filterForm.value.to,
          [`getLowStockOnly`]: this.filterForm.value.getLowStockOnly,
        });
      } else {
        this.changeFilter.emit({
          [`${this.col.dataField}From`]: this.filterForm.value.from,
          [`${this.col.dataField}To`]: this.filterForm.value.to,
        });
      }
      this.updateDisplayedFilter();
    });
  }

  protected updateForm(state: { [key: string]: string | number }): void {
    if (this.col.minStock) {
      this.filterForm.patchValue(
        {
          from: state[`${this.col.dataField}From`],
          to: state[`${this.col.dataField}To`],
          getLowStockOnly: state[`getLowStockOnly`],
        },
        { emitEvent: false },
      );
    } else {
      this.filterForm.patchValue(
        {
          from: state[`${this.col.dataField}From`],
          to: state[`${this.col.dataField}To`],
        },
        { emitEvent: false },
      );
    }
    this.updateDisplayedFilter();
  }

  private updateDisplayedFilter(): void {
    if (
      this.filterForm.value.from ||
      this.filterForm.value.to ||
      (this.filterForm.value.getLowStockOnly == 'true' &&
        this.col.minStock == true)
    ) {
      if (this.filterForm.value.from || this.filterForm.value.to) {
        this.displayedFilter = `${
          this.filterForm.value.from || this.filterForm.value.from == 0
            ? this.filterForm.value.from
            : '∞'
        } - ${
          this.filterForm.value.to || this.filterForm.value.to == 0
            ? this.filterForm.value.to
            : '∞'
        } ${
          this.filterForm.value.getLowStockOnly
            ? this.translateService.instant('TABLE.LOW_STOCK')
            : ''
        }`;
      } else {
        this.displayedFilter = `${
          this.filterForm.value.getLowStockOnly
            ? this.translateService.instant('TABLE.LOW_STOCK')
            : ''
        }`;
      }
    } else {
      if (this.filterForm.value.from === 0 && this.filterForm.value.to === 0) {
        this.displayedFilter = `${this.filterForm.value.from}-${this.filterForm.value.to}`;
      } else {
        this.displayedFilter = '';
      }
    }
  }

  getShownNumber(row: any): string {
    const cell = row[this.col.dataField];
    const digitsAfterDot = this.data.digitsAfterDot ?? 2;
    switch (this.data.type) {
      case NumberColumnType.Float: {
        return formatNumber(cell, 'en', this.UI_CONFIG.currencyFormat);
      }
      case NumberColumnType.Percent: {
        return (
          formatNumber(cell, 'en', `1.${digitsAfterDot}-${digitsAfterDot}`) +
          '%'
        );
      }
      case NumberColumnType.Decimal: {
        const value = formatNumber(
          cell,
          'en',
          `1.${digitsAfterDot}-${digitsAfterDot}`,
        );

        return value === '∞'
          ? '—'
          : value + (this.data.prefix ? ' ' + this.data.prefix : '');
      }
      case NumberColumnType.Currency: {
        return `${formatNumber(
          cell,
          'en',
          `1.${digitsAfterDot ?? this.UI_CONFIG.currencyFormat ?? 4}-${
            digitsAfterDot ?? this.UI_CONFIG.currencyFormat ?? 4
          }`,
        )}`;
      }
      case NumberColumnType.FullNumber: {
        return `${formatNumber(cell, 'en', `1.0-0`)}`;
      }
    }
  }

  get hasQuantity() {
    return this.data.type === NumberColumnType.Quantity;
  }

  getUnit(row) {
    const UMO = row[this.data.UOMFieldName];
    return ` ${
      UMO !== undefined ? this.translateService.instant(getUMOAcronym(UMO)) : ''
    }`;
  }
  getCell(row) {
    const cell = row[this.col.dataField];
    return ` ${formatNumber(cell, 'en', this.UI_CONFIG.currencyFormat)}`;
  }

  get className(): object {
    return {
      'font-color-dark font-family-regular':
        this.data.type === NumberColumnType.Decimal,
      'font-color-dark': this.data.type !== NumberColumnType.Decimal,
      [this.col.className]: true,
    };
  }

  classNameArr = (row): string[] => {
    if (this?.data?.styleContent) {
      return this.data?.styleContent(row);
    } else {
      return [];
    }
  };
}
