import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-number-range-picker',
  templateUrl: './number-range-picker.component.html',
  styleUrls: ['./number-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberRangePickerComponent implements OnInit {
  @Input()
  filterForm: UntypedFormGroup;

  @Input()
  lowStock = false;

  from: number;
  to: number;
  stock: string;

  ngOnInit(): void {
    this.from = this.filterForm.controls.from.value;
    this.to = this.filterForm.controls.to.value;
    this.stock = this.filterForm.controls.getLowStockOnly.value;
    this.filterForm.controls.getLowStockOnly.value
      ? ''
      : this.filterForm.controls.getLowStockOnly.setValue('');
  }
}
