<div
  clickOutside (clickOutside)="isDatePickerVisible ? onToggleDatePicker(false): null"
  class="date-range-picker-wrapper position-relative"
>
  <div class="range-picker-wrapper flex-column" [class.d-flex]="isDatePickerVisible">
    <div class="range-picker-header w-100 pb-4 d-flex justify-content-center align-items-center">
      {{selectedYear}}
      <div class="d-flex flex-column ml-2">
        <app-icon [icon]="'date-picker-arrow-up'"
                  [class.disabled]="selectedYear >= currentYear"
                  class="icon-d-flex" (click)="toggleYear('up')"></app-icon>
        <app-icon [icon]="'date-picker-arrow-up'"
                  style="transform: rotate(180deg)" class="icon-d-flex"
                  (click)="toggleYear('down')"
        ></app-icon>
      </div>
    </div>
    <div class="range-picker-body w-100">
      <div class="row m-0">
        <ng-container *ngFor="let month of monthsList; let i = index;">
          <div
            [class.disabled]="isDisabled(month)"
            [class.ml-0]="(i+1) % 3 === 1"
            [class.mr-0]="(i+1) % 3 === 0"
            [class.current]="selectedYear === currentYear && month.value === currentMonth"
            [class.selected]="month.value === selectedMonth"
            class="col-4 range-picker-col d-flex align-items-center justify-content-center p-0"
            (click)="onSelect(month)"
          >
            <div
              class="font-size-14 line-height-normal font-weight-normal font-color-dark"
            >
              {{month.name}}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
