import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { IdlePopupComponent } from './idle-popup.component';
import { IconModule } from '@optimo/ui-icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [IdlePopupComponent],
  exports: [IdlePopupComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    IconModule,
    TranslateModule,
  ],
})
export class IdlePopupModule {}
