import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCodeMessageKey } from './types';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private defaultErrorMessageKey = 'GLOBAL_ERROR_MESSAGES.Default';

  public getClientMessage(error: Error): string {
    return error.message ? null : this.defaultErrorMessageKey;
  }

  public getServerMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 403:
      case 413:
      case 500:
        return this.getMessageByHttpErrorStatusCode(error);
      case 400:
        return this.getMessageByHttpErrorCode(error);
      case 418:
        return this.getMessageByHttpErrorCode(error);
      default:
        return this.defaultErrorMessageKey;
    }
  }

  private getMessageByHttpErrorStatusCode(error: HttpErrorResponse): string {
    switch (error.status) {
      case 403:
        return 'GLOBAL_ERROR_MESSAGES.Forbidden';
      case 413:
        return 'GLOBAL_ERROR_MESSAGES.RequestEntityTooLarge';
      default:
        return this.defaultErrorMessageKey;
    }
  }

  private getMessageByHttpErrorCode(error: HttpErrorResponse): string {
    return (
      ErrorCodeMessageKey.get(error.error.errorCode) ??
      this.defaultErrorMessageKey
    );
  }
}
