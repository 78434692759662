import { Injectable } from '@angular/core';

import { LocalizationService } from '@optimo/core';
import { Location } from '@angular/common';
import { arrayOflanguages } from '../../../../config/ui-config';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard {
  constructor(
    private location: Location,
    private translationService: LocalizationService,
  ) {}

  canActivate() {
    const lang = this.location.path().split('/');
    if (lang[2] && arrayOflanguages.includes(lang[2])) {
      this.translationService.switchLanguage(lang[2], false);
    }
    return true;
  }
}
