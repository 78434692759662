import { TranslateService } from '@ngx-translate/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CellDirective } from './directives/cell.directive';
import { HeaderCellDirective } from './directives/header-cell.directive';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { CdkTable, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { PaginatorComponent } from './paginator/paginator.component';
import { Actions } from './column/column-templates/actions-column/actions-column.component';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { isEmpty, cloneDeep, mapValues, isEqual } from 'lodash-es';
import { ColumnData, ColumnType, SelectionData } from './types';
import { NumberColumnType } from './column/column-templates/number-column/number-column.component';
import { textIsTruncated } from '@optimo/util/text-is-truncated';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  private _displayedColumns: ColumnData[];
  public textIsTruncated = textIsTruncated;

  @ViewChild(PaginatorComponent)
  _internalPaginator: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(CdkTable, { static: true }) table: CdkTable<any>;

  @ViewChildren(forwardRef(() => CellDirective))
  rowEditCellTmpls: QueryList<CellDirective>;

  @ContentChild(CdkRowDef, { static: true })
  rowDefTmpl: CdkRowDef<any>;

  @ContentChild(CdkHeaderRowDef, { static: true })
  headerRowDefTmpl: CdkHeaderRowDef;

  @ContentChildren(forwardRef(() => CellDirective))
  cellTmpls: QueryList<CellDirective>;

  @ContentChildren(forwardRef(() => HeaderCellDirective))
  headerCellTmpls: QueryList<HeaderCellDirective>;

  private _dataSource: MatTableDataSource<any>;
  _totals = {};
  defaultStateLength = 0;

  @Input() set unselectAll(value) {
    if (value) {
      this.selection.clear();
      this.emitSelectionChanged();
    }
  }
  @Input()
  dataSourceGetter: (currentState: any) => Observable<any>;
  @Input()
  set dataSource(value: any) {
    if (!this._dataSource) {
      this._dataSource = new MatTableDataSource<any>(value);
    } else {
      this._dataSource.data = value;
    }
    this.loading = false;
    if (this.selection) {
      this.selection.clear();
      this.emitSelectionChanged();
    }
  }

  get dataSource(): any {
    return this._dataSource;
  }

  @Output()
  currencySwitch = new EventEmitter<NumberColumnType>();

  @Input()
  totalCount: number;

  @Input()
  dropdownKeyForStaticValueSelector: string;

  @Input()
  selectable = true;

  @Input()
  actions: Actions;

  @Input()
  indexable: boolean;

  @Input()
  overflow = true;

  @Input()
  emptyData = true;

  @Input()
  sortable = true;

  @Input()
  paginable = true;

  @Input()
  defaultPagination = true;

  @Input()
  staticData: boolean;

  @Input()
  filterable = true;

  @Input()
  defaultSort: string;

  // Blur row with given number, default set to
  @Input()
  hiddenRow;

  @Input()
  defaultSortDirection: 'ASC' | 'DESC' = 'ASC';

  @Input()
  border = true;

  @Input()
  set displayedColumns(value: ColumnData[]) {
    const totalCoefficients = value.reduce(
      (prev, curr) => prev + (curr.widthCoefficient || 1),
      0,
    );
    this._displayedColumns = value
      .map((col) => {
        if (col.widthCoefficient !== null) {
          col.widthPercent =
            ((col.widthCoefficient || 1) / totalCoefficients) * 100;
        }
        return col;
      })
      .filter((x) => (x.isUzProject ? !x.removeForUZ : true));

    this.displayedDateColumns = this._displayedColumns.filter(
      (col) =>
        col.columnType !== ColumnType.Select &&
        col.columnType !== ColumnType.Actions,
    );
  }

  get displayedColumns(): ColumnData[] {
    return this._displayedColumns;
  }

  private _state: { [key: string]: string | number };

  @Input('state')
  set state(value: { [key: string]: string | number }) {
    const alreadyExisted = !!this._state;
    this._state = cloneDeep(value);
    this.updatePaginatorState();
    this.updateSortState();

    if (
      this.defaultPagination &&
      alreadyExisted &&
      (isEmpty(value) ||
        !value?.sortField ||
        !value?.sortOrder ||
        !value?.pageSize ||
        (!value?.pageIndex && value?.pageIndex !== 0))
    ) {
      this.initDefaultState();
    }
  }

  get state(): { [key: string]: string | number } {
    return this._state;
  }

  @Input()
  pageSizeOptions =
    window.innerHeight > 768 ? [20, 50, 100] : [10, 25, 50, 100];
  // nothing
  @Input()
  inlineEdit: boolean;

  @Input()
  hasColumnSelector: boolean;

  @Input()
  hasInsideSelectAll = true;

  @Input()
  multipleSelect = true;

  @Input('externalPaginator')
  _externalPaginator: PaginatorComponent;

  @Input()
  showTotals = false;

  @Input()
  set tableTotalsData(value: any) {
    this._totals = value;
  }

  @Output()
  stateChanged = new EventEmitter<{ [key: string]: string | number }>();

  @Output()
  selectionChanged = new EventEmitter<SelectionData>();

  @Output()
  rowClick = new EventEmitter<any>();

  @Output()
  rowActivate = new EventEmitter<any>();

  @Output()
  rowEdit = new EventEmitter<any>();

  @Output()
  rowViewAs = new EventEmitter<any>();

  @Output()
  rowDelete = new EventEmitter<any>();

  @Output()
  showHideToggle = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();
  selection: SelectionModel<any>;
  columnType = ColumnType;
  private editRowForm: UntypedFormGroup;
  editingRow: any;
  displayedDateColumns: ColumnData[];
  loading: boolean;
  viewportUnderSm: boolean;
  private loadedFromQuery = false;
  private initialLoaded = false;

  private queryloadCount = 0;
  private stateLoadCount = 0;

  private firstTimeLoad = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth > 768
      ? (this.viewportUnderSm = false)
      : (this.viewportUnderSm = true);
  }

  @Input()
  selectableCustomEnable: (row: any) => boolean = (row) => row && false;

  private refreshSubject$ = new Subject<boolean>();
  @Input() set refreshSubject(subject$: Observable<any>) {
    subject$.subscribe((res = false) => {
      this.refreshSubject$.next(res);
    });
  }
  constructor(
    @Optional()
    @Inject('UI_CONFIG')
    public UI_CONFIG: { currencyLabel: string },
    private formBuilder: UntypedFormBuilder,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {
    if (!this.UI_CONFIG) {
      this.UI_CONFIG = { currencyLabel: '₾' };
    }
  }

  ngOnInit(): void {
    this.initializeDataSourceGetter();
    this.initializeTable();
    this.setViewportUnderSm();
  }

  ngAfterViewInit(): void {
    this.subscribeToPaginator();
    this.initDefaultState();
    this.initializeTableForStaticData();
  }

  private initializeDataSourceGetter(): void {
    if (this.dataSourceGetter) {
      this._dataSource = new MatTableDataSource<any>();
      this.listenQueryParamsAndRefresh();
    }
  }

  private initializeTable(): void {
    if (this.table && this.rowDefTmpl) {
      this.table.addRowDef(this.rowDefTmpl);
    }
    this.initTable();
  }

  private initializeTableForStaticData(): void {
    if (this.staticData) {
      if (this.sortable) {
        this._dataSource.sort = this.sort;
      }
      if (this.paginable && this.defaultPagination) {
        this._dataSource.paginator = this.paginator;
      }
    }
  }

  private subscribeToPaginator(): void {
    if (this.paginator) {
      this.paginator.page
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((e) => this.updateState(e));
    }
  }

  private setViewportUnderSm(): void {
    this.onResize({ target: { innerWidth: window.innerWidth } });
  }

  private initDefaultState(): void {
    if (this.dataSourceGetter) {
      const sortField = this.route.snapshot.queryParams['sortField'];
      const sortOrder = this.route.snapshot.queryParams['sortOrder'];
      if (sortField) {
        this.defaultSort = sortField;
        this.sort.active = this.sortField;
      }
      if (sortOrder) {
        this.defaultSortDirection = sortOrder;
        this.sort.direction = this.sortDirection;
      }
    }
    const state = {
      sortField: this.sortField,
      sortOrder: this.defaultSort && this.sortDirection.toLocaleUpperCase(),
      pageIndex: this.paginable && this.paginator.pageIndex,
      pageSize: this.paginable && this.paginator.pageSize,
    };
    this.defaultStateLength = Object.values(state).length;
    this.updateState(state);
  }

  private initTable(): void {
    if (this.selectable) {
      this.displayedColumns.unshift({
        dataField: 'select',
        columnType: ColumnType.Select,
        caption: 'Select',
        filterable: false,
        sortable: false,
        editable: false,
      });
      this.selection = new SelectionModel<any>(this.multipleSelect);
    }
    if (this.indexable) {
      this.displayedColumns.unshift({
        dataField: 'index',
        columnType: ColumnType.Index,
        caption: '#',
        filterable: false,
        sortable: false,
        editable: false,
      });
    }
    if (this.actions) {
      this.displayedColumns.push({
        dataField: 'actions',
        columnType: ColumnType.Actions,
        caption: '',
        filterable: false,
        sortable: false,
        editable: false,
      });
      if (this.inlineEdit) {
        this.createEditRowForm();
      }
    }
  }

  private createEditRowForm(): void {
    const controlsConfig = this.displayedDateColumns.reduce(
      (prev: any, curr: any, i: number) => {
        if (i === 1) {
          return { [prev.dataField]: [''], [curr.dataField]: [''] };
        }
        return { ...prev, [curr.dataField]: [''] };
      },
    );
    this.editRowForm = this.formBuilder.group(controlsConfig);
  }

  private updateState(data: object): void {
    if (!this.staticData) {
      this.loading = true;
    }
    this.cdr.detectChanges();
    data = { pageIndex: 0, ...data };
    const state = { ...this.state, ...data };
    this.stateChanged.emit(state);
    // To avoid loading onChangeQuery function twice on Admin-panel project
    if (!this.loadedFromQuery) {
      this.onChangeQuery(state);
    }
    this.initialLoaded = true;
    this.loadedFromQuery = false;
    this.stateLoadCount += 1;
  }

  private listenQueryParamsAndRefresh() {
    combineLatest([
      this.refreshSubject$.pipe(startWith(true)),
      this.route.queryParams,
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([subjNextValue, queryParams]) => ({ subjNextValue, queryParams })),
      )
      .subscribe(({ subjNextValue, queryParams }) => {
        if (!isEmpty(queryParams)) {
          const currentState = {
            ...mapValues(queryParams, (v) => {
              if (Array.isArray(v)) {
                return v.map(decodeURI);
              }
              return decodeURI(v);
            }),
            pageIndex: queryParams.pageIndex ? queryParams.pageIndex - 1 : 0,
          };

          this.queryloadCount += 1;
          // To avoid loading onChangeQuery function twice on Admin-panel project
          if (subjNextValue && !this.initialLoaded) {
            this.onChangeQuery(currentState, subjNextValue);
            this.refreshSubject$.next(false);
            this.loadedFromQuery = true;
          }

          // Need new request from admin-panel, when SubjectValue is true
          if (
            !this.firstTimeLoad &&
            subjNextValue &&
            this.queryloadCount >= this.stateLoadCount
          ) {
            this.onChangeQuery(currentState, subjNextValue);
            this.refreshSubject$.next(false);
          }

          this.firstTimeLoad = false;
        }
      });
  }

  private onChangeQuery(state, sendRequest = false) {
    if (this.dataSourceGetter) {
      delete state.previousPageIndex;
      delete state.length;
      if (!isEqual(this.state, state) || sendRequest) {
        this.state = state;
        this.router
          .navigate([], {
            queryParams: {
              ...mapValues(state, (v) => {
                if (Array.isArray(v) && v.length) {
                  return v.map(encodeURI);
                }
                const encodedValue =
                  (v?.toString() && encodeURI(v as string)) || null;
                return encodedValue;
              }),
              pageIndex: +state.pageIndex + 1,
            },
            replaceUrl: true,
            queryParamsHandling: 'merge',
          })
          .then(() => {
            this.dataSourceGetter(state)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((data) => {
                this.dataSource = data.data;
                this.totalCount = data.totalCount;
                this.updatePaginatorState();
              });
          });
      }
    }
  }

  private emitSelectionChanged(): void {
    this.selectionChanged.emit({
      selected: this.selection.selected,
      isAllSelected: this.isAllSelected,
    });
    this.cdr.detectChanges();
  }

  onSortChange({ active, direction }: Sort): void {
    if (this.paginable) {
      this.paginator.pageIndex = 0;
    }
    if (active && direction) {
      this.updateState({
        sortField: active,
        sortOrder: direction.toUpperCase(),
      });
    } else {
      const sortField = this.defaultSortDirection && this.defaultSort;
      let sortOrder = this.defaultSort && this.defaultSortDirection;
      if (sortField === active) {
        sortOrder = 'ASC';
      }

      this.updateState({
        sortField,
        sortOrder,
      });
    }
  }

  onRowSelectionToggle(row: any): void {
    this.selection.toggle(row);
    this.emitSelectionChanged();
  }

  is(capable: string, row: any): boolean {
    if (!this.actions) return false;
    const action = this?.actions[capable];
    return action instanceof Function ? action(row) : action;
  }

  hasAnyAction(row): boolean {
    return (
      this.is('editable', row) ||
      this.is('deletable', row) ||
      this.is('hasViewAs', row) ||
      this.is('activatable', row) ||
      this.is('hideable', row)
    );
  }

  onSelectAllToggle(): void {
    this.isAllSelected
      ? this.selection.clear()
      : this.dataSource.data.forEach(
          (row) =>
            (this.hasAnyAction(row) || this.selectableCustomEnable(row)) &&
            this.selection.select(row),
        );
    this.emitSelectionChanged();
  }

  onChangeFilter(filter: object): void {
    this.updateState(filter);
  }

  onRowClick(row: any): void {
    this.rowClick.emit(row);
  }

  onRowEditToggle(row: any): void {
    if (!this.inlineEdit) {
      this.rowEdit.emit(row);
      return;
    }
    if (this.editingRow === row) {
      this.editingRow = null;
      return;
    }
    this.editingRow = row;

    this.doEachControl((control: AbstractControl, key: string) => {
      control.setValue(row[key]);
    });
  }

  onRowEditSave(row: any): void {
    this.doEachControl((control: AbstractControl, key: string) => {
      row[key] = control.value;
    });
    this.onRowEditToggle(row);
  }

  onRowDelete(row: any): void {
    if (!this.inlineEdit) {
      this.rowDelete.emit(row);
      return;
    }
    const rowIndex = this.dataSource.data.indexOf(row);
    this.dataSource.data.splice(rowIndex, 1);
    this.dataSource = this.dataSource.data;
  }

  onRowShowHide(row: any): void {
    this.showHideToggle.emit(row);
  }

  onRowViewAs(row: any): void {
    this.rowViewAs.emit(row);
  }

  isFieldEditable(row: any, name: string): boolean {
    if (this.editingRow !== row) {
      return false;
    }

    const col = this.displayedColumns.find((c) => c.dataField === name);
    return col && col.editable;
  }

  get isAllSelected(): boolean {
    const selectablesLength = this.dataSource.data?.reduce(
      (accumulator, row) =>
        this.hasAnyAction(row) || this.selectableCustomEnable(row)
          ? accumulator + 1
          : accumulator,
      0,
    );
    return (
      this.selection.selected.length === selectablesLength &&
      (this.selection.selected.length || selectablesLength)
    );
  }

  get displayedColumnNames(): string[] {
    return this.displayedColumns
      .filter((col) => !col.hidden)
      .map((c) => c.dataField);
  }

  get displayedColumnFilterNames(): string[] {
    return this.displayedColumns
      .filter((col) => !col.hidden)
      .map((c) => c.dataField + '-filter');
  }

  get paginator(): PaginatorComponent {
    return this._externalPaginator || this._internalPaginator;
  }

  getCellTmpl(name: string): TemplateRef<any> {
    const directive = this.cellTmpls.find((c) => c.name === name);
    if (directive) {
      return directive.templateRef;
    }
    if (this.rowEditCellTmpls) {
      const editDirective = this.rowEditCellTmpls.find((c) => c.name === name);
      return editDirective && editDirective.templateRef;
    }
  }

  getHeaderCellTmpl(name: string): TemplateRef<any> {
    const directive = this.headerCellTmpls.find((h) => h.name === name);
    return directive && directive.templateRef;
  }

  getFormControl(name: string): AbstractControl {
    return this.editRowForm.controls[name];
  }

  private doEachControl(
    fn: (control: AbstractControl, key: string) => void,
  ): void {
    for (const key in this.editRowForm.controls) {
      if (this.editRowForm.controls[key]) {
        const control = this.editRowForm.controls[key];
        fn(control, key);
      }
    }
  }

  private updatePaginatorState(): void {
    if (this.paginable && this.paginator && this.state) {
      if (this.state.pageIndex || this.state.pageIndex === 0) {
        this.paginator.pageIndex = +this.state.pageIndex;
      }
      if (this.state.pageSize) {
        this.paginator.pageSize = +this.state.pageSize;
      }
    }
  }

  private updateSortState(): void {
    if (this.sortable && this.sort && this.state) {
      this.sort.direction = this.sortDirection;
      this.sort.active = this.sortField;
    }
  }

  private get sortDirection(): SortDirection {
    const direction = (this.state?.sortOrder as string)?.toLocaleLowerCase();
    return (
      direction === 'asc' || direction === 'desc'
        ? direction
        : this.defaultSortDirection.toLocaleLowerCase()
    ) as SortDirection;
  }

  private get sortField(): string {
    return this.state?.sortField &&
      this.displayedColumns
        ?.map((c) => c.dataField)
        .some((n) => n === this.state.sortField)
      ? (this.state.sortField as string)
      : this.defaultSort;
  }

  rowIsSelected(row: any): boolean {
    return this.selection && this.selection.selected.indexOf(row) > -1;
  }

  rowIsHidden(row: any): boolean {
    // if row hasOwnProperty rowCanBeHidden and it's true return it , else check normal behaviour whether row status is 2, or there is @input additional hiddenRow
    if ('rowCanBeHidden' in row) {
      return row.rowCanBeHidden;
    } else {
      return (
        row?.status === 2 ||
        (row && row.status && row.status === this.hiddenRow)
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
