import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { INotification } from '../../../modules/notifications/models/INotification';
import { NotificationsSignalrService } from './notifications-signalr.service';
import { select, Store } from '@ngrx/store';
import * as fromNotification from '../../../modules/notifications/state';
import { addNotification } from '../../../modules/notifications/state/notification.actions';
import { ClientService } from '@optimo/core';

@Injectable({
  providedIn: 'root',
})
export class AsyncNotificationsService {
  private unsubscribe$ = new Subject<void>();
  private currentNotifications: BehaviorSubject<INotification[]> =
    new BehaviorSubject([]);

  private readNotifications: Set<number> = new Set();

  public notifications: Observable<
    IAsyncNotification<ISaleOrderAsyncNotificationData>
  > = this.currentNotifications.pipe(
    tap((notifications) =>
      console.log('dev => notificationsArr', notifications),
    ),
    mergeMap((notifications: INotification[]) => from(notifications)),
    tap((notification) =>
      console.log('dev => notifications flattened', notification),
    ),
    map((notification) => {
      let parsedDescription;
      try {
        parsedDescription = JSON.parse(notification.description);
      } catch (e) {
        parsedDescription = notification.description; // Fallback to the original string if it's not valid JSON
      }
      return {
        ...notification,
        description: parsedDescription,
      };
    }),
  );

  constructor(
    private notificationsSignalrService: NotificationsSignalrService,
    private _store: Store,
    private http: ClientService,
  ) {}

  unsubscribeSignalR() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.notificationsSignalrService.stopConnection();
  }

  listenToNotificationSignalR() {
    this.notificationsSignalrService.receivedNotification
      .pipe(
        tap((notification: INotification) => {
          if (notification !== null) {
            this._store.dispatch(addNotification({ notification }));
            if (!notification.read) {
              this.markAsRead(notification).subscribe();
            }
          }
        }),
        switchMap(() => this.selectNotificationList()),
        tap(this.currentNotifications),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((notifications) =>
        console.log('dev => notifications =>', notifications),
      );
  }

  private selectNotificationList(): Observable<INotification[]> {
    const notificationsRequest = this._store
      .pipe(select(fromNotification.selectNotificationList))
      .pipe(
        catchError(() => of({ list: [] })),
        map((notifications: INotification[]) => {
          return notifications.filter((notification: INotification) => {
            return (
              notification.type === 1 &&
              !notification.read &&
              !this.readNotifications.has(notification.id)
            );
          });
        }),
        tap((notifications: INotification[]) => {
          notifications.forEach((notification: INotification) => {
            this.readNotifications.add(notification.id);
          });
        }),
        tap((notifications: INotification[]) =>
          console.log('dev => filtered notifications', notifications),
        ),
      );

    return notificationsRequest;
  }

  private markAsRead(notification: INotification) {
    return this.http.post<void>(`notifications/read/${notification.id}`).pipe(
      tap(() => this.readNotifications.add(notification.id)),
      catchError(() => of(null)),
    );
  }
}

export interface IAsyncNotification<T> {
  id: number;
  name: string;
  isImportant: boolean;
  sendDate: Date;
  seen: boolean;
  read: boolean;
  description: T;
}

export interface ISaleOrderAsyncNotificationData {
  Type: 0;
  Payload: {
    SaleOrderId: number;
    Status: RsUploadStatus;
    AdditionaInfo: string;
  };
}

export enum RsUploadStatus {
  Success = 55,
  Failed = 5599,
}
