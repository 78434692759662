export enum ShippingStatuses {
  Draft = 0,
  Shipped = 5,
  SucceededUploadedToRS = 55,
}

export const shippingStatusData = {
  [ShippingStatuses.Draft]: 'Shipping.STATUS_LIST.DRAFT',
  [ShippingStatuses.Shipped]: 'Shipping.STATUS_LIST.SHIPPED',
  [ShippingStatuses.SucceededUploadedToRS]:
    'Shipping.STATUS_LIST.UPLOADED_TO_RS',
};
