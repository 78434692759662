import { Injectable } from '@angular/core';
import { UrlTree, Router, Route, UrlSegment } from '@angular/router';
import { ClientService, Service } from '@optimo/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface MaintenanceDTO {
  id: number;
  microservice: MaintenanceMicroservice;
  status: MaintenanceStatus;
}

enum MaintenanceStatus {
  Inactive = 0,
  Active = 1,
}

enum MaintenanceMicroservice {
  Dashboard = 0,
  Identity = 1,
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard {
  constructor(private cleintService: ClientService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    // const getMaintenance = await fetch('https://cdn.optimo.ge/status.txt', {
    //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //   mode: 'cors', // no-cors, *cors, same-origin
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //   headers: {
    //     'Content-Type': 'text/plain',
    //     accept: 'text/html',
    //   },
    // });

    // const response = await getMaintenance.text();

    // console.log('dev => getMaint', response);

    // return response.includes('maintenance')
    //   ? this.router.createUrlTree(['maintenance'])
    //   : true;

    return this.cleintService
      .get<MaintenanceDTO>('maintenance?maintenanceMicroservice=0', {
        service: Service.Admin,
      })
      .pipe(
        catchError((e) => {
          console.error('dev => maintenance check failed', e);
          return of({ id: 0, microservice: 0, status: 0 } as MaintenanceDTO);
        }),
        // tap((maintenanceActive) =>
        //   console.log('dev => maintenanceActive', maintenanceActive),
        // ),
        map((maintenanceActive) =>
          maintenanceActive.status === MaintenanceStatus.Active
            ? this.router.createUrlTree(['maintenance'])
            : true,
        ),
      );
  }

  canLoad(
    _route: Route,
    _segments: UrlSegment[],
  ): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceOverGuard {
  constructor(private cleintService: ClientService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.cleintService
      .get<MaintenanceDTO>('maintenance?maintenanceMicroservice=0', {
        service: Service.Admin,
      })
      .pipe(
        catchError((e) => {
          console.error('dev => maintenance check failed', e);
          return of({ id: 0, microservice: 0, status: 0 } as MaintenanceDTO);
        }),
        // tap((maintenanceActive) =>
        //   console.log('dev => maintenanceActive', maintenanceActive),
        // ),
        map((maintenanceActive) =>
          maintenanceActive.status === MaintenanceStatus.Active
            ? true
            : this.router.createUrlTree(['/']),
        ),
      );
  }

  canLoad(
    _route: Route,
    _segments: UrlSegment[],
  ): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
