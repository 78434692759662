import { Pipe, PipeTransform } from '@angular/core';
import { currencyLabel } from 'apps/dashboard/src/config/ui-config';

@Pipe({
  name: 'currencyLabelPipe',
})
export class CurrencyLabelPipe implements PipeTransform {
  transform(value: any) {
    return `${value}${value ? ' ' : ''}${currencyLabel}`;
  }
}
