export enum HistoryPaymentMethods {
  Card = 1,
  Cash,
  PrePayment,
  Consignation,
  Return,
  Other = 9,
}

export const pairOfHistoryPaymentMethods = [
  {
    value: HistoryPaymentMethods.Cash,
    label: 'SUPPLIERS.PAYMENT_METHODS.CARD',
  },
  {
    value: HistoryPaymentMethods.Card,
    label: 'SUPPLIERS.PAYMENT_METHODS.CASH',
  },
  {
    value: HistoryPaymentMethods.PrePayment,
    label: 'SUPPLIERS.PAYMENT_METHODS.PRE_PAYMENT',
  },
  {
    value: HistoryPaymentMethods.Consignation,
    label: 'SUPPLIERS.PAYMENT_METHODS.CONSIGNATION',
  },
  {
    value: HistoryPaymentMethods.Return,
    label: 'SUPPLIERS.PAYMENT_METHODS.RETURN',
  },
  {
    value: HistoryPaymentMethods.Other,
    label: 'SUPPLIERS.PAYMENT_METHODS.OTHER',
  },
];
