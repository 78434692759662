export enum HistoryItemTypes {
  Purchase = 1,
  Payment = 2,
  Return = 3,
  Auto_Purchase = 4,
}

export const pairOfHistoryItemTypes = [
  { value: HistoryItemTypes.Purchase, label: 'GENERAL.PURCHASE' },
  {
    value: HistoryItemTypes.Payment,
    label: 'ENTITY_CLIENTS.TRANSACTIONS.PAYMENT',
  },
  { value: HistoryItemTypes.Return, label: 'GENERAL.BACK' },
];
