export const environment = {
  production: false,
  preProduction: false,
  AUTH_ROUTE: 'https://identity.dev.optimo.uz/V1/',
  INVENTORY_ROUTE: 'https://core.dev.optimo.uz/api/V1/',
  NOTIFICATIONS_HUBS: 'https://core.staging.optimo.uz/hubs',
  REPORTING_ROUTE: 'https://reporting.dev.optimo.uz/api/V1/',
  INTEGRATIONS_ROUTE: 'https://integration.dev.optimo.uz/api/V1/',
  BILLING_ROUTE: 'https://billing-api.dev.optimo.uz/api/v1/',
  MIXPANEL_TOKEN: '',
  GTAG_ID: '',
  APP_TYPE: 'Staff',
  CATALOGUE_ROUTE: 'https://suppliers-api.dev.optimo.uz/V1/',
  POS_ROUTE: 'https://pos.dev.optimo.uz/api/V1/',
  PURCHASE_ROUTE: 'https://purchase.dev.optimo.uz/api/v1/',
  LANDING_ROUTE: 'https://dev.optimo.uz/',
  ADMIN_ROUTE: 'https://admin-api.dev.optimo.uz/api/v1/',
  PAYMENT_URL: 'https://payment-api.dev.optimo.uz/api/v1/',
  PAYMENT_SUCCESS_EDIT: '',
  PAYMENT_SUCCESS: '',
  PAYMENT_FAIL: '',
  PAYMENT_UNAUTH_EDIT: '',
  PAYMENT_UNAUTH_SUCCESS: '',
  PAYMENT_UNAUTH_FAIL: '',
};

// uncommen after backend is done
// export const environment = {
//   production: false,
//   preProduction: false,
//   AUTH_ROUTE: 'https://identity.dev.optimo.uz/V1/',
//   INVENTORY_ROUTE: 'https://core.dev.optimo.uz/api/V1/',
//   REPORTING_ROUTE: 'https://reporting.dev.optimo.uz/api/V1/',
//   INTEGRATIONS_ROUTE: 'https://integration.dev.optimo.uz/api/V1/',
//   MIXPANEL_TOKEN: '',
//   GTAG_ID: '',
//   APP_TYPE: 'Staff',
//   CATALOGUE_ROUTE: 'https://suppliers-api.dev.optimo.uz/V1/',
//   POS_ROUTE: 'https://pos.dev.optimo.uz/api/V1/',
//   PURCHASE_ROUTE: 'https://purchase.dev.optimo.uz/api/v1/',
// };
