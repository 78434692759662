import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private messageSource = new BehaviorSubject('default message');
  private selectedRowsObservable = new BehaviorSubject('');
  private $quickInventorizationObservable = new BehaviorSubject('');
  public locationsChange = this.messageSource.asObservable();
  public notificationEmitter = new BehaviorSubject([]);
  public selectedRows = this.selectedRowsObservable.asObservable();
  public $inventorizationItems =
    this.$quickInventorizationObservable.asObservable();

  locationsUpdated() {
    this.messageSource.next('updated message');
  }

  onSelectTableRow(value) {
    this.selectedRowsObservable.next(value);
  }

  onSelectInventorizationItems(value) {
    this.$quickInventorizationObservable.next(value);
  }
}
