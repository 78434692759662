<div class="d-flex flex-column">
  <app-headbar
    #headbar
    (logout)="onLogout()"
    [sidebarComponent]="sidebar"
    [userDetails]="userDetails"
  ></app-headbar>
  <div class="authorized-layout">
    <app-sidebar 
      #sidebar
      [hasGlovoIntegration]="this.glovoIntegration"
      [userDetails]="userDetails"
    ></app-sidebar>

    <div class="authorized-layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
