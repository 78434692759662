import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { StorageService } from '@optimo/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../config/config.service';

@Injectable({ providedIn: 'root' })
export class NotificationsSignalrService {
  private hubConnection!: signalR.HubConnection;
  private receivedNotificationObservable = new BehaviorSubject(null);
  public receivedNotification =
    this.receivedNotificationObservable.asObservable();
  private audio = new Audio();
  private originalTitle = '';

  constructor(
    private storage: StorageService,
    private titleService: Title,
    private _configService: ConfigService,
  ) {
    this.originalTitle = this.titleService.getTitle();
  }

  // private playNotificationSound(type = 'regular'): void {
  //   this.audio.src = `assets/audios/${type}-notification.${
  //     type === 'regular' ? 'mp3' : 'wav'
  //   }`;
  //   this.audio.load();
  //   setTimeout(() => {
  //     this.audio
  //       .play()
  //       .catch((error) =>
  //         console.error('dev => WS: Error playing sound:', error),
  //       );
  //   }, 200);
  // }

  private startConnection() {
    const accessToken = this.storage.getAccessToken();
    if (!accessToken) {
      return true;
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.NOTIFICATIONS_HUBS}/notification`, {
        accessTokenFactory: () => {
          return accessToken;
        },
      }) // Specify the hub URL
      .withHubProtocol(new signalR.JsonHubProtocol()) // Explicitly set JSON
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.on('ReceiveNotification', (data: any) => {
          // this.playNotificationSound(data.type == 1 ? 'rs' : 'regular');
          this.titleService.setTitle(`✉️ - ${this.originalTitle}`);
          setTimeout(() => {
            this.titleService.setTitle(`${this.originalTitle}`);
          }, 5000);
          console.log(`dev => WS: Receive notification`);
          this.receivedNotificationObservable.next(data);
        });
        console.log('dev => WS: SignalR connection started');
      })
      .catch((err) =>
        console.error('dev => WS: Error starting SignalR connection:', err),
      );
  }

  private on(methodName: string, callback: (...args: any[]) => void): void {
    if (this.hubConnection) {
      this.hubConnection.on(methodName, callback);
    }
  }

  public stopConnection(): void {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => console.log('dev => WS: SignalR connection stopped'));
    }
  }

  public connect() {
    if (this._configService.getConfig()?.isAdmin) {
      return;
    }
    this.startConnection();
  }
}
