<app-column
  [dataField]="col.dataField"
  [caption]="col.caption"
  [col]="col"
  [asyncCaption]="col.asyncCaption"
  [sortable]="col.sortable"
  [widthPercent]="col.widthPercent"
  (cellClick)="cellClick.emit($event)"
  [isOpen]="isOpen"
>
  <ng-template *ngIf="headerCellTmpl" #headerCell>
    <ng-container *ngTemplateOutlet="headerCellTmpl"></ng-container>
  </ng-template>

  <ng-template #cell let-row="row">
    <ng-container
      *ngTemplateOutlet="
        cellTmpl;
        context: { cell: row[col.dataField], row: row }
      "
    ></ng-container>
    <div *ngIf="!cellTmpl" [ngClass]="col.className">
      <span
		[matTooltip]="getStaticPermissionsData(col, row) | translate"
		#ref1 [matTooltipDisabled]="textIsTruncated(ref1)"
		class="mw-100 d-inline-block text-truncate"
      >
        {{ getStaticPermissionsData(col, row) | translate}}
      </span>
    </div>
  </ng-template>

  <ng-template *ngIf="col.filterable" #filterCell>
    <div class="d-flex align-items-center h-100" (click)="onToggleFilter($event);  isOpen=!isOpen">
      <input
        data-hj-allow
        type="text"
        class="table-filter-input"
        [placeholder]="'TABLE.SELECT' | translate"
        readonly
        [id]="'table-header-multiselect-'+col.dataField"
        [value]="selectedValuesLabel || ''"
        [matTooltip]="selectedValuesLabel || ''"
        #ref2 [matTooltipDisabled]="textIsTruncated(ref2)"
      />
      <app-icon
        [icon]="'arrow-down'"
        [ngClass]="{'visibility-hidden pointer-events-none' : selectedKey?.length}"
        class="th-icon cursor-pointer"
      ></app-icon>
    </div>
    <button
      *ngIf="filterSelected"
      type="reset"
      class="filter-reset"
      (click)="onReset()"
    >
      <app-icon icon="filter-reset"></app-icon>
    </button>
    <div
      *ngIf="isFilterVisible"
      clickOutside
      (clickOutside)="clickOutside()"
      [class.overflow-hidden]="col.multiselectButton"
      class="custom-select-body d-block top-100pct global-custom-scroll max-h-43vh"
    >
      <div class="d-flex flex-column" style="max-height: inherit">
        <div
          [class.global-custom-scroll]="col.multiselectButton"
        >
          <div
            class="custom-select-option cursor-pointer"
            *ngFor="let key of objectKeys(col.data); let i=index;"
            [class.active]="selectedKey === key"
            (click)="onSelectionChange(key)"
          >
            <div
              class="custom-checkbox custom-checkbox-selected-blue justify-content-start w-100"
            >
              <input
                data-hj-allow
                type="checkbox"
                [checked]="isItemSelected(key)"
                class="custom-checkbox-control"
                [id]="'table-header-multiselect-option-'+col.dataField+'-'+i"
              />
              <label
                class="custom-checkbox-label text-truncate"
                [id]="'table-header-multiselect-option-label-'+col.dataField+'-'+i"
                [matTooltip]="col.data[key] | translate"
                #reference [matTooltipDisabled]="textIsTruncated(reference)"
              >
              <span class="custom-select-text ml-2 pl-1 font-weight-normal text-truncate"
                    [matTooltip]="col.data[key] | translate"
                    #reference2 [matTooltipDisabled]="textIsTruncated(reference2)">{{
                col.data[key] | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="col.multiselectButton" class="px-3 pb-3 pt-2">
          <button
            [disabled]="isDisabled"
            [id]="col.dataField+'_multiselect_filter_button'"
            type="button"
            (click)="emitChange()"
            class="btn btn-primary btn-sm btn-block w-100 font-family-bold font-color-white font-size-14 h-42px"
          >
            {{'TABLE.CONFIRM' | translate}}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</app-column>
