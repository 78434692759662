import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NotificationsService } from '../notifications/notifications.service';
import { ErrorService } from './error.service';
import { LoggingService } from './logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  public handleError(error: Error | HttpErrorResponse): void {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationsService);

    let message: string;

    if (error instanceof HttpErrorResponse) {
      message = errorService.getServerMessage(error);
      notifier.sayError(message);
    } else {
      message = errorService.getClientMessage(error);
      if (message) notifier.sayError(message);
    }

    logger.logError(error);
  }
}
