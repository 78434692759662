<div
  class="bg-white rounded mat-dialog-boxshadow"
>
  <div class="d-flex align-items-center justify-content-between pr-sm-12px pr-2 h-56px">
    <div></div>
    <button class="btn bg-transparent rounded-0 p-6px" (click)="$event.preventDefault(); onClose()">
      <app-icon icon="close"></app-icon>
    </button>
  </div>
  <div class="px-sm-56px px-3 pb-64px d-flex flex-column align-items-between align-items-center">
    <div class="warning-icon rounded-circle mb-32px d-flex align-items-center justify-content-center">
      <app-icon icon="{{data.icon}}" class="cursor-pointer"></app-icon>
    </div>
    <div class="font-color-dark font-size-sm-22 font-size-16 font-family-bold text-center mb-3 line-h-normal">
      {{data.title | translate}}
    </div>
    <div
      class="font-color-regular font-size-16 line-h-normal text-center line-h-21px"
      [innerHTML]="data.description | translate: {package_url: generatePackagesUrl}"
    ></div>
  </div>

  <div class="pt-3 pb-22px pl-12px pr-12px d-flex justify-content-center align-items-center border-top rounded-bottom">
    <div class="font-family-regular font-color-dark d-flex align-items-center">
      <app-icon icon="mobile-phone"></app-icon>
      <span class="font-family-regular font-size-16 cursor-pointer pl-2">032 2 45 45 40</span>
    </div>
  </div>
</div>
