import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthGuard } from './core/services/guards/unauth.guard';
import { ChangePasswordGuard } from './core/services/guards/change-password.guard';
import { AuthGuard } from './core/services/guards/auth.guard';
import { AuthorizedLayoutComponent } from './layouts/authorized-layout/authorized-layout.component';
import { LanguageGuard } from './core/services/guards/language.guard';
import {
  MaintenanceGuard,
  MaintenanceOverGuard,
} from './core/services/guards/maintenance.guard';

const routes: Routes = [
  {
    path: 'maintenance',
    canActivate: [MaintenanceOverGuard],
    loadChildren: () =>
      import('./pages/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule,
      ),
  },
  {
    path: 'login',
    canActivate: [MaintenanceGuard, LanguageGuard, UnauthGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'change-password',
    canActivate: [MaintenanceGuard, UnauthGuard, ChangePasswordGuard],
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule,
      ),
  },
  {
    path: 'access-token/:accessToken',
    canActivate: [MaintenanceGuard, UnauthGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('./pages/verify/verify.module').then((m) => m.VerifyModule),
  },
  {
    path: 'reset-password',
    canActivate: [MaintenanceGuard, UnauthGuard],
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule,
      ),
  },
  {
    path: 'print-barcode',
    canLoad: [MaintenanceGuard, AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/print-barcode/print-barcode.module').then(
        (m) => m.PrintBarcodeModule,
      ),
  },
  {
    path: 'dashboard-invoice/:id',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('libs/ui/billing-invoice-pdf/src/index').then(
        (m) => m.UiBillingInvoicePdfModule,
      ),
  },
  {
    path: 'print-template/:id',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('libs/ui/print-legal-entity-template/src/index').then(
        (m) => m.PrintLegalEntityTemplateModule,
      ),
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canLoad: [MaintenanceGuard, AuthGuard],
    canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
