<form class="w-100">
  <div class="d-flex align-items-center justify-content-between pl-sm-4 pl-3 pr-sm-18px pr-2 h-60px">
    <div></div>
    <button class="btn bg-transparent rounded-0 border-0 p-6px d-flex justify-content-center align-items-center" (click)="$event.preventDefault(); onCancel()">
      <app-icon icon="close"></app-icon>
    </button>
  </div>
  <div class="px-56px pb-32px d-flex flex-column align-items-between align-items-center">
    <div class="bg-pink-blue w-132px h-132px rounded-circle mb-32px d-flex align-items-center justify-content-center 
    border border-table-border-color">
      <app-icon icon="mobile-laptop-warning" class="mt-n2"></app-icon>
    </div>
    <div class="d-flex align-items-center flex-shrink-1 flex-column">
      <span class="text-center font-family-bold font-size-20 m-0 line-h-30px">
        {{(!isBlockedByPayment
        ? 'GENERAL.PAYMENTS_BILLING.PAYMENT_WARNING_BLOCKED_BY_ADMIN'
        : 'GENERAL.PAYMENTS_BILLING.PAYMENT_WARNING_BLOCKED_BY_DEBT') | translate
        }}
      </span>
      <div *ngIf="!isBlockedByPayment" class="d-flex align-items-center text-center m-0 d-flex align-items-center mt-32px">
        <app-icon icon="mobile-phone" class="cursor-pointer mr-3"></app-icon>
        <span class="font-family-regular font-size-16 cursor-pointer ml-2 line-h-normal">032 2 45 45 40</span>
      </div>
      <div class="d-flex flex-column text-center mt-32px mb-32px" *ngIf="isBlockedByPayment">
        <span
          class="font-size-16 font-family-regular mb-2 pb-1">{{ 'GENERAL.PAYMENTS_BILLING.TOTAL_PAYMENT' | translate}}</span>
        <span
          class="font-size-24 font-color-red font-family-bold line-h-28px">{{data.shouldPay | number:'1.2-2':'en' }}
          ₾</span>
      </div>
    </div>
    <button
      class="btn btn-primary w-100 font-color-white px-4 font-size-14 font-family-bold h-48px min-w-95px py-2"
      (click)="onShowPaymentMethods()"
      type="button"
      *ngIf="isBlockedByPayment"
    >
        <span
          *ngIf="loading"
          class="spinner-border text-white spinner-border-sm"
        ></span>
      <div class="d-flex align-items-center" *ngIf="!loading" >
        <div class="col-2 px-0"></div>
        <span class="flex-grow-1">{{ 'GENERAL.PAYMENTS_BILLING.PAYMENT_METHODS'  | translate}}</span>
        <div class="col-2 px-0 d-flex justify-content-end">
          <app-icon icon="arrow-next" class="cursor-pointer"></app-icon>
        </div>
      </div>
    </button>
  </div>

  <div
    class="bg-color-gray h-58px pl-12px pr-12px d-flex justify-content-center align-items-center border-top rounded-bottom">
    <span class="font-family-regular font-size-14 font-color-dark">
      {{"GENERAL.PAYMENTS_BILLING.BALANCE_ID" | translate}}: {{ data.balanceId.toString() | padString:'0':5}}
    </span>
    <button
      class="btn p-0 w-36px h-36px ml-2 rounded-circle btn-copy-clipboard"
      (click)="copyValue(data.balanceId)"
      type="button"
      [matTooltip]="('GENERAL.PAYMENTS_BILLING.COPY' | translate)"
    >
      <app-icon icon="copy-value"></app-icon>
    </button>
  </div>
</form>

