import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '@optimo/ui-icon';
import { LimitPopupComponent } from './limit-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from '@optimo/ui-table';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    IconModule,
    TranslateModule.forChild(),
    TableModule,
  ],
  declarations: [LimitPopupComponent],
  exports: [LimitPopupComponent],
})
export class LimitPopupModule {}
