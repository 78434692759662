import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ClientService, StorageService } from '@optimo/core';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { LocationStatus } from '../../enums/location-status.enum';
import { ConfigService } from 'apps/dashboard/src/app/core/services/config/config.service';

export enum LocalStorageKeys {
  locationId = 'locationId',
}

export interface ILocation {
  id: number;
  name: string;
  address: string;
  managerName: string;
  phoneNumber: string;
  status: number;
  statusDescription: number;
  canDelete: boolean;
  isForExtra: boolean;
  autoUploadEntitySalesToRS: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private _selectedId: number;

  private _locations: ILocation[];

  constructor(
    private storage: StorageService,
    private client: ClientService,
    private _configService: ConfigService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get id(): number {
    if (!this._selectedId?.toString) {
      if (!this.storage.getAccessToken()) return 1;
      this._selectedId = this.storage.get(LocalStorageKeys.locationId);
      if (!this._selectedId?.toString) this.initLocation();
    }
    return this._selectedId;
  }

  set id(locationId: number) {
    this.storage.set(LocalStorageKeys.locationId, locationId);
    this._selectedId = locationId;
  }

  get getStorageLocationId() {
    return this.storage.get(LocalStorageKeys.locationId);
  }
  initLocation() {
    const accessToken: string = this.storage.getAccessToken();
    if (!accessToken) return;

    const config = this._configService.getConfig();

    if (!this.storage.get('locationId')) {
      if (config?.userRole === 'Staff') {
        if (config.permissions.length > 0) {
          const firstUserPermission: any = config.permissions[0]['locationId'];
          this.storage.set('locationId', firstUserPermission);
        } else {
          this.storage.set('locationId', 1);
        }
      } else {
        this.storage.set('locationId', 1);
      }
    }
    this.id = this.storage.get('locationId');
  }

  private fetchLocations(): Observable<ILocation[]> {
    return this.client
      .get('locations', {
        params: new HttpParams({
          fromObject: {
            pageIndex: '0',
            sortField: 'id',
            sortOrder: 'ASC',
            pageSize: '777',
            status: LocationStatus.Enabled.toString(),
          },
        }),
      })
      .pipe(
        takeUntil<{ data: ILocation[]; totalCount: number }>(this.unsubscribe$),
        map<{ data: ILocation[]; totalCount: number }, ILocation[]>(
          (locationsResponse) => locationsResponse.data,
        ),
        tap((locations) => (this._locations = locations)),
      );
  }

  getLocations(): Observable<ILocation[]> {
    return this._locations?.length
      ? of(this._locations)
      : this.fetchLocations();
  }

  makeLocationsEmpty() {
    this._locations = [];
  }
  resetLocation() {
    this.storage.remove(LocalStorageKeys.locationId);
    this._selectedId = undefined;
  }
  unsetLocation() {
    this._selectedId = undefined;
  }
}
