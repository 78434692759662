import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LocalizationService, RoutingStateService } from '@optimo/core';
import { MixpanelService } from '@optimo/mixpanel';
import { environment } from '../environments/environment';
import { isUz } from '../config/ui-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    routingState: RoutingStateService,
    _mixpanelService: MixpanelService,
    localizationService: LocalizationService,
  ) {
    routingState.loadRouting();
    routingState.trackGoogleAnalitics();

    localizationService.init(isUz);
    _mixpanelService.setToken(environment.MIXPANEL_TOKEN);
  }

  ngOnInit() {
    const platform = window?.navigator?.platform;
    let os = '';

    if (platform.indexOf('Mac') !== -1) {
      os = 'macos';
      document.documentElement.classList.add(`os-${os}`);
    }
  }
}
