import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ClientService, RoutingStateService } from '@optimo/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { padString } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { Clipboard } from '@angular/cdk/clipboard';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface IData {
  accountId: string;
  cardType: number;
  expirationDate: string;
  isDefault: boolean;
  maskedCard: string;
  secondaryId: number;
}

@Component({
  selector: 'app-payment-warning-dialog-dialog',
  templateUrl: './payment-warning-dialog.component.html',
  styleUrls: ['./payment-warning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentWarningDialogComponent implements OnInit, OnDestroy {
  public dueAmount = 0;
  private copy$ = new Subject<number>();
  private unsubscribe$ = new Subject<void>();
  public requestIsSent: boolean;
  public loading: boolean;

  constructor(
    private clipboard: Clipboard,
    private notificator: NotificationsService,
    private dialogRef: MatDialogRef<PaymentWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { cards: IData[]; shouldPay: number; balanceId: number },
    private cdr: ChangeDetectorRef,
    private client: ClientService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private _routingState: RoutingStateService,
    private _domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancel();
    });
    this.copy$
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.clipboard.copy(padString(res.toString(), 5, '0'));
        this.notificator.saySuccess(
          'GENERAL.PAYMENTS_BILLING.BALANCE_ID_SUCCESS',
        );
      });
  }
  copyValue(value: number) {
    this.copy$.next(value);
  }
  onCancel(): void {
    this.dialogRef.close(false);
  }

  onShowPaymentMethods() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.dialogRef.close(true);
    }, 100);
  }

  get hasCard() {
    return this.data.cards !== undefined && !!this.data.cards.length;
  }

  get isBlockedByPayment() {
    return this.data.shouldPay !== 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
