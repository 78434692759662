<form class="max-h-80vh d-flex flex-column">
  <div
    class="d-flex align-items-center justify-content-between border-bottom border-table-border-color pl-sm-4 pl-3 pr-sm-18px pr-2 h-88px">
    <div (click)="goBack()" class="col-2 pl-2">
      <app-icon  *ngIf="showPayButton" icon="arrow-back" class="cursor-pointer" ></app-icon>
    </div>
    <span class="font-color-dark font-size-18 font-family-bold line-h-normal col text-center">
      {{ 'GENERAL.PAYMENTS_BILLING.PAYMENT_METHODS' | translate }}
		</span>
    <div class="col-2 px-0 d-flex justify-content-end">
      <button
      class="btn bg-transparent rounded-0 border-0 p-6px d-flex justify-content-center align-items-center"
      (click)="$event.preventDefault(); onCancel()"
    >
      <app-icon icon="close"></app-icon>
    </button>
    </div>
  </div>
  <div class="px-32px pb-32px pt-4 overflow-auto">
    <app-payment-methods-card
      [hasPayBtn]="showPayButton"
      [shouldPay]="data.shouldPay"
      [cards]="data.cards"
      [data]="paymentMethodInfos[0]"
      (onAddCard)="onAddBankAccount()"
      (onPay)="onCancel()"
      (onDelete)="onCancel()"
      class="d-block pb-12px"
    ></app-payment-methods-card>
    <app-payment-methods-card [data]="paymentMethodInfos[1]" class="d-block pb-12px"></app-payment-methods-card>
    <app-payment-methods-card [data]="paymentMethodInfos[2]"></app-payment-methods-card>
  </div>

  <div
    class="bg-color-gray h-58px pl-12px pr-12px d-flex justify-content-center align-items-center border-top rounded-bottom"
    *ngIf="hasBalanceId">
    <span class="font-family-regular font-size-14 font-color-dark">
      {{ "GENERAL.PAYMENTS_BILLING.BALANCE_ID" | translate }}: {{ data.balanceId.toString() | padString:'0':5 }}
    </span>
    <button
      class="btn p-0 w-36px h-36px ml-2 rounded-circle btn-copy-clipboard"
      (click)="copyValue(data.balanceId)"
      type="button"
      [matTooltip]="('GENERAL.PAYMENTS_BILLING.COPY' | translate)"
    >
      <app-icon icon="copy-value"></app-icon>
    </button>
  </div>
</form>

