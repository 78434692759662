<form class="w-100">
  <div class="d-flex align-items-center justify-content-between pl-sm-4 pl-3 pr-sm-18px pr-2 h-60px" *ngIf="data.hasCloseBtn">
    <div></div>
    <button class="btn bg-transparent rounded-0 border-0 p-6px d-flex justify-content-center align-items-center" (click)="$event.preventDefault(); onCancel()">
      <app-icon icon="close"></app-icon>
    </button>
  </div>
  <div
    [class.pt-4]="!data.hasCloseBtn"
    class="px-4 pb-4 d-flex flex-column align-items-between align-items-center">
    <div class="w-80px h-80px bg-pink-blue border border-table-border-color rounded-circle mb-32px d-flex align-items-center justify-content-center">
      <app-icon class="mt-n1" *ngIf="!data.success" icon="fail-red"></app-icon>
      <app-icon *ngIf="data.success" icon="check-green"></app-icon>
    </div>
    <div class="d-flex align-items-center flex-shrink-1 flex-column">
      <p class="text-center font-family-bold font-size-20 m-0 line-h-normal">{{data.title}}</p>
      <p class="text-center font-family-regular font-size-16 m-0 mt-3 line-h-20px">{{data.message}} </p>
    </div>
    <button
      (click)="onSubmit()"
      type="button"
      [class.mb-3]="!data.success"
      class="btn btn-primary font-color-white px-4 font-size-14 font-family-bold h-42px min-w-95px py-2 mt-40px approve-btn"
    >
        <span
          *ngIf="loading"
          class="spinner-border text-white spinner-border-sm"
        ></span>
      <span *ngIf="!loading">{{ data.btnText | translate}}</span>
    </button>
  </div>
</form>

