import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padString',
})
export class PadStringPipe implements PipeTransform {
  transform(value: string, padChar: string, length: number): string {
    if (value.length < length) {
      return value.padStart(length, padChar);
    }
    return value;
  }
}
