<div class="d-flex flex-column h-100">
	<div class="d-flex align-items-center justify-content-between border-bottom border-table-border-color pl-sm-4 pl-2
              pr-sm-18px pr-2 pt-sm-11px pt-14px pb-sm-12px pb-15px">
			  <div class="min-w-34px d-sm-none d-flex"></div>
		<div class="d-flex align-items-center w-100 justify-content-sm-start justify-content-center">
			<span class="font-color-dark font-size-sm-18 font-size-16 font-family-bold line-h-normal pr-2">
				{{'NOTIFICATIONS.NOTIFICATION' | translate}}
			</span>
			<app-icon icon="yellow-flag" *ngIf="data.isImportant" class="mt-n2px"></app-icon>
		</div>
		<button class="btn bg-transparent rounded-0 border-0 p-6px" (click)="onClose()">
			<app-icon icon="close" class="cursor-pointer"></app-icon>
		</button>
	</div>
	<div class="global-custom-scroll px-sm-4 px-3 max-h-u-sm-unset pb-4 max-h-492px">
		<div class="font-color-dark font-size-lg-20 font-size-16 font-family-bold pt-sm-4 pt-12px pb-2 text-truncate">{{data.name}}</div>
		<div class="font-color-dark font-size-12 font-family-regular pb-lg-20px pb-3 line-h-normal">
			{{data.sendDate | date: 'dd.MM.yy HH:mm' }}
		</div>
		<div  class="overflow-hidden ql-snow">
			<div class="text-break ql-editor p-0" [innerHTML]="data.description | safeHtml">

			</div>
		</div>
	</div>
</div>
