import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  //get version from angular files
  let version = '0';
  const regex = /main\..+\.js/;
  try {
    document.querySelectorAll('script').forEach((node) => {
      const x = node.src.toString();
      if (x && regex.test(x)) {
        version = x.match(regex)[0].split('.')[1];
        console.log(x, version);
      }
    });
  } catch {
    console.error(
      'angular version not found for translate files. default to 0',
    );
  }

  return new TranslateHttpLoader(
    http,
    'assets/i18n/',
    `.json?v=${version ?? 0}`,
  );
}
