import { Injectable } from '@angular/core';
import { ClientService } from '@optimo/core';
import { BehaviorSubject } from 'rxjs';
import { isGe } from '../../../../config/ui-config';
import { PackageType } from '../../enums/package-type.enum';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class LimitServiceService {
  public hasLimit$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private client: ClientService,
    private _configService: ConfigService,
  ) {}

  public checkLimit(): void {
    const hasPackageWithoutLimits =
      this._configService.getConfig()?.packageType !== PackageType.Plus &&
      this._configService.getConfig()?.packageType !== PackageType.Basic;
    if (isGe && !hasPackageWithoutLimits) {
      this.client.get<any>(`stockitems/has-limit`).subscribe((res) => {
        this.hasLimit$.next(res);
      });
    }
  }
}
