import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { INotification } from '../../models/INotification';
import * as fromNotification from '../../state';
import {
  clearNotifications,
  loadNotificationList,
  markAllAsSeen,
  markAsRead,
  updateUnseenNotificationQuantity,
} from '../../state/notification.actions';
import { MatDialog } from '@angular/material/dialog';
import { INotificationListRequest } from '../../models/INotificationListRequest';
import { NotificationViewComponent } from 'libs/ui/notification-view/src';
import { ClientService } from '@optimo/core';
import { takeUntil, tap } from 'rxjs/operators';
import { NotificationsSignalrService } from '../../../../core/services/notifications/notifications-signalr.service';
import { ConfigService } from '../../../../core/services/config/config.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public notifications: INotification[];
  public unseenQuantity = 0;
  public unseenNotificationQuantity$: Observable<number>;
  public showList: boolean;
  private _subscribtions: Subscription[] = [];
  private offset = 0;
  private _notificationListRequest: INotificationListRequest = {
    skip: 0,
    take: 10,
  };
  private unsubscribe$ = new Subject<void>();

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _client: ClientService,
    private _cdr: ChangeDetectorRef,
    private notificationsSignalrService: NotificationsSignalrService,
    private _configService: ConfigService,
  ) {}

  public ngOnInit(): void {
    this.initialize();
  }

  private listenToSignalR(): void {
    this.notificationsSignalrService.receivedNotification.subscribe(
      (notification) => {
        if (notification !== null) {
          this.notifications = [notification, ...(this.notifications || [])];
          this._store.dispatch(
            updateUnseenNotificationQuantity({
              unseen: this.unseenQuantity + 1,
            }),
          );
          console.log('dev => notification =>', notification);
          this._cdr.markForCheck();
        }
      },
    );
  }

  private isMobile(): boolean {
    return this.deviceType() === 'mobile';
  }

  private deviceType(): 'tablet' | 'mobile' | 'desktop' {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  public clickOutside(): void {
    if (!this.isMobile()) {
      this.toggleNotificationList(false);
    }
  }

  public onScroll() {
    this.offset += 1;
    this._notificationListRequest = {
      skip: this._notificationListRequest.take * this.offset,
      take: this._notificationListRequest.take,
    };
    this.loadNotificationList(0);
    this.getNotificationList();
  }

  public openNotification(notification: INotification): void {
    this.clickOutside();
    if (!notification?.read) {
      this._store.dispatch(markAsRead({ notification: notification }));
    }
    this.getNotification(notification);
  }

  getNotification(notification) {
    this._client
      .get(`notifications/${notification?.id}`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this._dialog.open(NotificationViewComponent, {
          data: response,
          disableClose: true,
          panelClass: [
            'mat-dialog-fullscreen-u-sm',
            'mat-w-700',
            'mat-overflow-hidden',
            'mat-max-h-90vh',
          ],
        });
      });
  }

  public toggleNotificationList(value?: boolean): void {
    const newValue = value === undefined ? !this.showList : value;
    this.showList = newValue;

    if (this.showList && !this._configService.getConfig()?.isAdmin) {
      this.markAllAsSeen();
    }
  }

  private initialize(): void {
    if (!this._configService.getConfig()?.isAdmin) {
      this.loadNotificationList(0);
      setTimeout(() => {
        this.loadNotificationList(1);
      }, 300);
      this.listenToSignalR();
      this.getNotificationList();
      this.getUnseenNotificationQuantity();
    }
  }

  private loadNotificationList(type?): void {
    this._store.dispatch(
      loadNotificationList({
        notificationListRequest: { ...this._notificationListRequest, type },
      }),
    );
  }

  private findAndOpenImportantNotification(): void {
    if (
      this.notifications.length &&
      JSON.parse(localStorage.getItem('showImportantNotification'))
    ) {
      localStorage.setItem('showImportantNotification', 'false');
      const notification: INotification = this.notifications.find(
        (not) => not.isImportant && not.type !== 1,
      );
      if (notification && !notification.read) {
        this.openNotification(notification);
      }
    }
  }

  private getNotificationList(): void {
    const sub$ = this._store
      .pipe(select(fromNotification.selectNotificationList))
      .subscribe((notifications: INotification[]) => {
        this.notifications = notifications;
        this.findAndOpenImportantNotification();
        this._cdr.markForCheck();
      });
    this._subscribtions.push(sub$);
  }

  private getUnseenNotificationQuantity(): void {
    this.unseenNotificationQuantity$ = this._store.pipe(
      select(fromNotification.unseenNotificationQuantity),
      tap((res) => {
        this.unseenQuantity = res;
      }),
    );
  }

  private markAllAsSeen(): void {
    this._store.dispatch(markAllAsSeen());
  }

  public ngOnDestroy(): void {
    this._subscribtions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
    this._store.dispatch(clearNotifications());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
