import { EOptimoProductType } from '@optimo/core';
import { INestedMenuItem } from '@optimo/ui-nested-menu';
import { MENUTREE } from '../../../../config/ui-config';

export interface IOptimoDashboardMenuItem extends INestedMenuItem {
  productTypes: EOptimoProductType[];
  isVisibleForAllLocations: boolean;
  pageName?: string;
  allowedRoles?: string[];
  translateId?: string;
}

export const MENU_TREE: IOptimoDashboardMenuItem[] = MENUTREE;
