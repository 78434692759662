export enum ELanguage {
  ka,
  en,
  ru,
}

export const arrayOflanguagesMap = {
  [ELanguage.ka]: 'ka',
  [ELanguage.en]: 'en',
  [ELanguage.ru]: 'ru',
};
