export function filterNavsByPagePermission(
  navigation: any[],
  pagePermissions: { [key: string]: string },
): any[] {
  // Recursive function to filter children based on permissions
  const filterChildren = (children: any[]): any[] => {
    return children
      .map((child) => {
        // If there's a permissionName, check it against pagePermissions
        if (child.permissionName && pagePermissions) {
          const permission = pagePermissions[child.permissionName];
          // If the permission does not include 'View', filter it out
          if (!permission || !permission.includes('View')) {
            return null;
          }
        }

        // Recursively filter any children this child might have
        if (child.children) {
          const filteredChildren = filterChildren(child.children);
          if (filteredChildren.length === 0) {
            return null; // Filter out parent if all children are removed
          }
          return { ...child, children: filteredChildren };
        }

        return child;
      })
      .filter(Boolean); // Remove any null items
  };

  // Apply filter to each top-level navigation item
  return navigation
    .map((item) => {
      if (item.children) {
        const filteredChildren = filterChildren(item.children);
        if (filteredChildren.length === 0) {
          return null; // Filter out parent if all children are removed
        }
        return { ...item, children: filteredChildren };
      }

      return item;
    })
    .filter(Boolean); // Remove any null items
}
