export enum EErrorCode {
  ConcurrencyFailure = 2,
  PasswordMismatch = 3,
  InvalidToken = 4,
  LoginAlreadyAssociated = 5,
  InvalidUserName = 6,
  InvalidEmail = 7,
  DuplicateUserName = 8,
  DuplicateEmail = 9,
  InvalidRoleName = 10,
  DuplicateRoleName = 11,
  UserAlreadyHasPassword = 12,
  UserLockoutNotEnabled = 13,
  UserAlreadyInRole = 14,
  UserNotInRole = 15,
  PasswordTooShort = 16,
  PasswordRequiresNonAlphanumeric = 17,
  PasswordRequiresDigit = 18,
  PasswordRequiresLower = 19,
  PasswordRequiresUpper = 20,
  InvalidPhoneNumber = 21,
  InvalidCredentials = 22,
  RefreshTokenUsed = 23,
  UserExists = 24,
  UserVerificationPending = 25,
  RefreshTokenNotFound = 26,
  UserNotFound = 27,
  NotificationNotSent = 28,
  AlreadyRegisteredException = 29,
  UserLockedOut = 30,
  IdentificationNumberExists = 31,
  PasswordResetPendingException = 33,
  MultipleLegalEntitiesException = 34,
  NoActiveLegalEntitiesException = 35,
  LegalEntitySuspendedException = 36,
  ContractAgreementMissingException = 37,
  DuplicatedCategoryName = 41,
  PriceTypeExists = 1057,
  ErrorCode550 = 550,
  NotificationPhoneNumberRequired = 3000,
  NotificationOtpRequired = 3001,
  NotificationInvalidPhoneNumber = 3002,
  NotificationOtpLimitExhausted = 3003,
  NotificationOtpExpired = 3004,
  NotificationOtpUsed = 3005,
  NotificationWrongOtp = 3006,
  CategoryWithActiveProduct = 99,
  SupplierDuplicate = 1054,
}

export const ErrorCodeMessageKey = new Map<EErrorCode, string>([
  [EErrorCode.ConcurrencyFailure, 'GLOBAL_ERROR_MESSAGES.ConcurrencyFailure'],
  [EErrorCode.PasswordMismatch, 'GLOBAL_ERROR_MESSAGES.PasswordMismatch'],
  [EErrorCode.InvalidToken, 'GLOBAL_ERROR_MESSAGES.InvalidToken'],
  [
    EErrorCode.LoginAlreadyAssociated,
    'GLOBAL_ERROR_MESSAGES.LoginAlreadyAssociated',
  ],
  [EErrorCode.InvalidUserName, 'GLOBAL_ERROR_MESSAGES.InvalidUserName'],
  [EErrorCode.InvalidEmail, 'GLOBAL_ERROR_MESSAGES.InvalidEmail'],
  [EErrorCode.DuplicateUserName, 'GLOBAL_ERROR_MESSAGES.DuplicateUserName'],
  [EErrorCode.DuplicateEmail, 'GLOBAL_ERROR_MESSAGES.DuplicateEmail'],
  [EErrorCode.InvalidRoleName, 'GLOBAL_ERROR_MESSAGES.InvalidRoleName'],
  [EErrorCode.DuplicateRoleName, 'GLOBAL_ERROR_MESSAGES.DuplicateRoleName'],
  [
    EErrorCode.UserAlreadyHasPassword,
    'GLOBAL_ERROR_MESSAGES.UserAlreadyHasPassword',
  ],
  [
    EErrorCode.UserLockoutNotEnabled,
    'GLOBAL_ERROR_MESSAGES.UserLockoutNotEnabled',
  ],
  [EErrorCode.UserAlreadyInRole, 'GLOBAL_ERROR_MESSAGES.UserAlreadyInRole'],
  [EErrorCode.UserNotInRole, 'GLOBAL_ERROR_MESSAGES.UserNotInRole'],
  [EErrorCode.PasswordTooShort, 'GLOBAL_ERROR_MESSAGES.PasswordTooShort'],
  [
    EErrorCode.PasswordRequiresNonAlphanumeric,
    'GLOBAL_ERROR_MESSAGES.PasswordRequiresNonAlphanumeric',
  ],
  [
    EErrorCode.PasswordRequiresDigit,
    'GLOBAL_ERROR_MESSAGES.PasswordRequiresDigit',
  ],
  [
    EErrorCode.PasswordRequiresLower,
    'GLOBAL_ERROR_MESSAGES.PasswordRequiresLower',
  ],
  [
    EErrorCode.PasswordRequiresUpper,
    'GLOBAL_ERROR_MESSAGES.PasswordRequiresUpper',
  ],
  [EErrorCode.InvalidPhoneNumber, 'GLOBAL_ERROR_MESSAGES.InvalidPhoneNumber'],
  [EErrorCode.InvalidCredentials, 'GLOBAL_ERROR_MESSAGES.InvalidCredentials'],
  [EErrorCode.RefreshTokenUsed, 'GLOBAL_ERROR_MESSAGES.RefreshTokenUsed'],
  [EErrorCode.UserExists, 'GLOBAL_ERROR_MESSAGES.UserExists'],
  [
    EErrorCode.UserVerificationPending,
    'GLOBAL_ERROR_MESSAGES.UserVerificationPending',
  ],
  [
    EErrorCode.RefreshTokenNotFound,
    'GLOBAL_ERROR_MESSAGES.RefreshTokenNotFound',
  ],
  [EErrorCode.UserNotFound, 'GLOBAL_ERROR_MESSAGES.UserNotFound'],
  [EErrorCode.NotificationNotSent, 'GLOBAL_ERROR_MESSAGES.NotificationNotSent'],
  [
    EErrorCode.AlreadyRegisteredException,
    'GLOBAL_ERROR_MESSAGES.AlreadyRegisteredException',
  ],
  [EErrorCode.UserLockedOut, 'GLOBAL_ERROR_MESSAGES.UserLockedOut'],
  [
    EErrorCode.IdentificationNumberExists,
    'GLOBAL_ERROR_MESSAGES.IdentificationNumberExists',
  ],
  [
    EErrorCode.PasswordResetPendingException,
    'GLOBAL_ERROR_MESSAGES.PasswordResetPendingException',
  ],
  [
    EErrorCode.MultipleLegalEntitiesException,
    'GLOBAL_ERROR_MESSAGES.MultipleLegalEntitiesException',
  ],
  [
    EErrorCode.NoActiveLegalEntitiesException,
    'GLOBAL_ERROR_MESSAGES.NoActiveLegalEntitiesException',
  ],
  [
    EErrorCode.LegalEntitySuspendedException,
    'GLOBAL_ERROR_MESSAGES.LegalEntitySuspendedException',
  ],
  [
    EErrorCode.ContractAgreementMissingException,
    'GLOBAL_ERROR_MESSAGES.ContractAgreementMissingException',
  ],
  [
    EErrorCode.DuplicatedCategoryName,
    'GLOBAL_ERROR_MESSAGES.DuplicatedCategoryName',
  ],
  [
    EErrorCode.NotificationPhoneNumberRequired,
    'GLOBAL_ERROR_MESSAGES.NotificationPhoneNumberRequired',
  ],
  [
    EErrorCode.NotificationOtpRequired,
    'GLOBAL_ERROR_MESSAGES.NotificationOtpRequired',
  ],
  [
    EErrorCode.NotificationInvalidPhoneNumber,
    'GLOBAL_ERROR_MESSAGES.NotificationInvalidPhoneNumber',
  ],
  [
    EErrorCode.NotificationOtpLimitExhausted,
    'GLOBAL_ERROR_MESSAGES.NotificationOtpLimitExhausted',
  ],
  [
    EErrorCode.NotificationOtpExpired,
    'GLOBAL_ERROR_MESSAGES.NotificationOtpExpired',
  ],
  [EErrorCode.NotificationOtpUsed, 'GLOBAL_ERROR_MESSAGES.NotificationOtpUsed'],
  [
    EErrorCode.NotificationWrongOtp,
    'GLOBAL_ERROR_MESSAGES.NotificationWrongOtp',
  ],
  [
    EErrorCode.SupplierDuplicate,
    'GLOBAL_ERROR_MESSAGES.SUPPLIER_ALREADY_EXISTS',
  ],
  [EErrorCode.CategoryWithActiveProduct, 'ERROR_MESSAGE.CATEGORY_DELETE_ERROR'],
  [EErrorCode.PriceTypeExists, 'PriceTypes.Duplicate_Error'],
]);
