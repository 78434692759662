<div class="notification-list-wrapper d-flex flex-column pb-2">
  <div class="w-100 px-sm-4 px-3 py-sm-22px pt-1 pb-2 font-size-18 font-family-bold line-h-normal">
    {{'NOTIFICATIONS.NOTIFICATIONS' | translate}}</div>
  <div
    class="flex-grow-1 d-flex flex-column global-custom-scroll px-2"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()"
  >
    <ng-container *ngFor="let notification of notifications">
      <div
        *ngIf="notification.type !==1"
        class="notification-list-item-wrapper w-100 px-sm-3 px-2 py-17px pb-18px cursor-pointer"
      >
        <div class="notification-list-item d-flex" (click)="openNotification.emit(notification)">
          <div class="d-flex align-items-start">
            <app-icon icon="yellow-flag" class="mr-2 mt-n3px" *ngIf="notification.isImportant"
                      matTooltip='მნიშვნელოვანი შეტყობინება'></app-icon>
          </div>
          <div class="notification-text-wrapper flex-grow-1" [class.ml-4]="!notification.isImportant">
            <div
              class="text-break font-size-14 font-family-regular font-color-dark notification-text">{{ notification.name }}
            </div>
            <div class="d-flex pt-7px">
            <span class="font-size-12 font-family-regular font-color-dark line-h-normal">{{
                notification.sendDate
                  | date: 'dd.MM.yy HH:mm'
              }}</span>

            </div>
          </div>
          <div class="ml-2 d-flex align-items-center min-w-10px">
          <span class="bg-blue rounded-circle w-10px h-10px" *ngIf="!notification.read">
          </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
