<div
  class="bg-white rounded"
>
<div
    class="d-flex align-items-center justify-content-between border-bottom border-table-border-color container-fluid pl-sm-4 pl-3 pr-sm-18px pr-2 h-60px"
  >
      <span class="text-dark font-size-18 font-family-bold"
        >{{"INACTIVE_POPUP.Inactive_title" | translate}}
      </span>
      <button (click)="onContinue()" class="btn bg-transparent rounded-0 border-0 p-6px d-flex justify-content-center align-items-center">
      <app-icon
        [icon]="'close'"
      ></app-icon>
      </button>

  </div>
  <div class="p-4 d-flex align-items-center font-size-16 font-family-regular font-color-dark line-h-normal">
     <span>{{"INACTIVE_POPUP.Inactive_text" | translate}}
    <span class="text-center d-inline-block w-18px">{{countDown}}</span>
       {{"INACTIVE_POPUP.Second" | translate}}.</span>
  </div>
  <div class="d-flex px-4 justify-content-end pb-4">
    <button
      class="btn mr-2 font-family-bold px-4 py-0 h-42px"
      type="button"
      (click)="onLogout()"
    >
      {{"INACTIVE_POPUP.Exit" | translate}}
    </button>
    <button
      class="btn btn-primary font-family-bold px-4 py-0 h-42px"
      (click)="onContinue()"
    >
      {{"INACTIVE_POPUP.Continue" | translate}}
    </button>
  </div>
</div>
