export enum EMonth {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export const Months = [
  { value: EMonth.Jan, name: 'იან' },
  { value: EMonth.Feb, name: 'თებ' },
  { value: EMonth.Mar, name: 'მარ' },
  { value: EMonth.Apr, name: 'აპრ' },
  { value: EMonth.May, name: 'მაი' },
  { value: EMonth.Jun, name: 'ივნ' },
  { value: EMonth.Jul, name: 'ივლ' },
  { value: EMonth.Aug, name: 'აგვ' },
  { value: EMonth.Sep, name: 'სექ' },
  { value: EMonth.Oct, name: 'ოქტ' },
  { value: EMonth.Nov, name: 'ნოე' },
  { value: EMonth.Dec, name: 'დეკ' },
];
