<app-column
  dataField="actions"
  [sortable]="false"
  [stickyEnd]="true"
  [hidden]="true"
  [widthPercent]="0"
>
  <ng-template #cell let-row="row">
    <div *ngIf="hasAnyAction(row)" class="table-row-actions">
      <ng-container *ngIf="editingRow !== row">

        <ng-container *ngFor="let action of actions?.customActions; let i=index;">
          <button
            *ngIf="!action.hide"
            type="button"
            [matTooltip]="action.tooltip"
            matTooltipClass="mat-tooltip-right"
            class="btn-table-action"
            [id]="'table-header-actions-btn-'+i+'-'+ row?.id"
            (click)="action.onClick(row); $event.stopPropagation()"
          >
            <app-icon [icon]="action.icon"></app-icon>
        </button>
        </ng-container>
        <button
          type="button"
          *ngIf="is('hideable', row)"
          class="btn-table-action"
          [id]="'table-header-actions-btn-hideable-'+ row?.id"
          [ngClass]="
            row.categoryStatus === ItemStatus.Disabled ? 'disableStatus' : ''
          "
          [matTooltip]="
            (row.categoryStatus === ItemStatus.Disabled
              ? 'TABLE.SHOW_CATEGORY'
              : row.status === ItemStatus.Disabled
                ? 'TABLE.SHOW'
                : 'TABLE.HIDE') | translate
          "
          (click)="showHideToggle.emit(row); $event.stopPropagation()"
        >
          <app-icon
            [icon]="row.status === ItemStatus.Disabled ? 'show' : 'hidden'"
          ></app-icon>
        </button>

        <button
          type="button"
          *ngIf="is('hasViewAs', row)"
          [matTooltip]="'TABLE.GO_TO_LEGAL_ENTITY' | translate"
          [matTooltip]="viewAsTooltip | translate"
          matTooltipClass="mat-tooltip-right-edge"
          class="btn-table-action"
          [id]="'table-header-actions-btn-hasViewAs-'+ row?.id"
          (click)="rowViewAs.emit(row); $event.stopPropagation()"
        >
          <app-icon class="mt-n2px" [icon]="'link-open-suppliers'"></app-icon>
        </button>

        <button
          type="button"
          *ngIf="is('activatable', row)"
          class="btn-table-action"
          [matTooltip]="is('activatable', row)"
          [id]="'table-header-actions-btn-activatable-'+ row?.id"
          (click)="rowActivateToggle.emit(row); $event.stopPropagation()"
        >
          <app-icon
            [icon]="'favorites'"
          ></app-icon>
        </button>

        <button
          type="button"
          *ngIf="is('editable', row)"
          [matTooltip]="'TABLE.EDIT' | translate"
          matTooltipClass="mat-tooltip-right"
          class="btn-table-action"
          [id]="'table-header-actions-btn-editable-'+ row?.id"
          (click)="rowEditToggle.emit(row); $event.stopPropagation()"
        >
          <app-icon [icon]="'edit-tbls'"></app-icon>
        </button>
        <button
          type="button"
          *ngIf="is('deletable', row)"
          [matTooltip]="'TABLE.DELETE' | translate"
          class="btn-table-action"
          [id]="'table-header-actions-btn-deletable-'+ row?.id"
          (click)="onDelete(row); $event.stopPropagation()"
        >
          <app-icon [icon]="'trash-tbls'"></app-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="editingRow === row">
        <button
          mat-button
          (click)="rowEditSave.emit(row); $event.stopPropagation()"
        >
          <mat-icon>
            save
          </mat-icon>
        </button>
        <button
          mat-button
          (click)="rowEditToggle.emit(row); $event.stopPropagation()"
        >
          <mat-icon>
            clear
          </mat-icon>
        </button>
      </ng-container>
    </div>
  </ng-template>
</app-column>
