import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface legalEntityDataBilling {
  id: string;
  secondaryId: number;
  billingPackageName: string;
  billingPackageDisplayName: string;
  packagePrice: number;
  numberOfAdditionalPointsOfSale: number;
  additionalPointOfSalePrice: number;
  sumOfAdditionalPointsOfSale: number;
  numberOfActiveDevices: number;
  devicePrice: number;
  monthPrice: number;
  balanceAmount: number;
  dueAmount: number;
  shouldPay: number;
  billingStatusDescription: string;
  billingStatus: BillingStatus;
  balanceId: number;
}

export enum BillingStatus {
  Active = 0,
  TurnedOff = 1,
  NonActive = -1,
}

@Component({
  selector: 'app-package-card',
  templateUrl: './packages-card.component.html',
  styleUrls: ['./packages-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackagesCardComponent {
  @Input()
  legalEntityData: legalEntityDataBilling;
}
