<div class="custom-select" [class.active]="active">
  <div #selectHeader class="custom-select-header col-visibility-selector" (click)="toggleSelect()">
    <app-icon icon="column-add"></app-icon>
  </div>
  <ng-container *ngIf="active">
    <div class="custom-select-body left-unset right-0 top-100pct-plus-8px border border-input-border-gray" clickOutside
      (clickOutside)="toggleSelect($event)">
      <div
        *ngFor="let col of visibilityManagableColumns"
        class="custom-select-option px-0"
        [class.disabled]="col.canNotChangeVisibility"
        >
        <div class="custom-checkbox custom-checkbox-selected-blue justify-content-start w-100 h-100" [id]="col.dataField+'-column'">
          <input data-hj-allow type="checkbox" [id]="col.dataField" class="custom-checkbox-control"
            [disabled]="col.canNotChangeVisibility" [checked]="!col.hidden" (change)="onVisibleChange(col)" />
          <label [for]="col.dataField" class="custom-checkbox-label w-100 h-100 px-3">
            <span class="custom-select-text pl-3">
              <ng-container
                *ngIf="col.asyncCaption"
              >
                {{ col.asyncCaption | async | translate}}
              </ng-container>
              <ng-container
                *ngIf="!col.asyncCaption"
              >
                {{ col.caption | translate: {currencyLabel: UI_CONFIG.currencyLabel} }}
              </ng-container>
            </span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
</div>
