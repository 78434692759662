<div class="bg-white rounded d-flex flex-column border border-table-border-color payment-method-card">
  <div class="d-flex px-4 pb-4 pt-3">
    <div class="w-52px mr-20px d-flex justify-content-center">
      <div *ngIf="data?.icon">
        <app-icon icon="{{data?.icon}}"></app-icon>
      </div>
    </div>
    <div class="d-flex flex-column" [class.w-100]="hasPayBtn">
      <span class="font-color-dark font-size-15 font-family-bold  line-h-normal pt-2"           
      [ngClass]="{'mb-10px': !hasPayBtn,'mb-1': hasPayBtn}">
        {{data?.title | translate }}
      </span>
      <ng-container *ngIf="hasCard">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <app-icon *ngIf="isMC" icon="master-card" class="icon-h-32px cursor-pointer mr-2"></app-icon>
            <app-icon *ngIf="isVISA" icon="visa-card" class="icon-h-32px cursor-pointer mr-2"></app-icon>
            <div class="d-flex align-items-center">
              <span class="font-size-14 font-family-bold">{{maskedCard}}</span>
              <div class="d-flex ml-1" *ngIf="hasCard && hasPayBtn">
                <div
                  (click)="onEditCard()"
                  id="edit-bank-card"
                  class="d-flex ml-2 align-items-center justify-content-center w-32px h-32px bg-sidebar-gray rounded-circle cursor-pointer"
                >
                  <app-icon icon="edit" class="icon-w-20px icon-h-20px cursor-pointer"></app-icon>
                </div>
                <div
                  (click)="onDeleteCard()"
                  id="delete-bank-card"
                  class="d-flex ml-10px align-items-center justify-content-center w-32px h-32px bg-sidebar-gray rounded-circle cursor-pointer"
                >
                  <app-icon icon="trash" class="icon-w-20px icon-h-20px  cursor-pointer"></app-icon>
                </div>
              </div>

            </div>
          </div>
          <button
            *ngIf="hasPayBtn"
            class="btn btn-primary font-color-white px-4 font-size-14 font-family-bold h-42px min-w-95px py-2 w-112px"
            type="button"
            (click)="onPayDebt()"
          >
            <span
              *ngIf="loading"
              class="spinner-border text-white spinner-border-sm"
            ></span>
            <span *ngIf="!loading">{{'GENERAL.PAYMENTS_BILLING.PAYMENT' | translate}}</span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!hasCard">
        <li
          *ngFor="let description of data?.list; let i=index;"
          class="font-size-12 font-family-regular font-color-dark-disabled line-h-19px"
          [class.mb-4]="data.listHasBullets && i!==(data.list.length-1)"
          [ngStyle]="{'list-style-type' : 'none', 'padding-right': '26px'}"
        >
          <span [innerHTML]="description | translate"></span>
        </li>
      </ng-container>
      <ng-container *ngIf="data?.hasButton && !hasCard">
        <button type="button" class="d-flex align-items-center border-0 bg-transparent w-max-content mt-12px p-0 ml-n1" (click)="onAddBankAccount()">
          <app-icon *ngIf="!loading" icon="plus-blue"></app-icon>
          <div *ngIf="loading" class="w-24px h-24px d-flex align-items-center justify-content-center">
            <span class="spinner-border font-color-blue spinner-border-sm"></span>
          </div>
          <span class="ml-2 cursor-pointer font-color-blue font-family-bold font-size-13">{{ 'GENERAL.PAYMENTS_BILLING.ADD_CARD' | translate}}</span>
        </button>
      </ng-container>
    </div>
  </div>
 
  <!-- this is removed from design needs delete after some time if not needed <div class="d-flex w-100 bg-sidebar-gray p-4 align-items-center font-family-regular"
  *ngIf="data?.warning && !hasCard">
    <app-icon icon="exclamation-mark-circle"></app-icon>
    <span class="ml-14px font-size-13">dsadas{{data?.warning | translate}}</span>
  </div> -->
</div>

