<div class="sidebar" [class.active]="active">
  <div class="sidebar-header">
    <div class="d-md-none justify-content-between align-items-center w-100 header-actions">
      <div class="col-2 pl-sm-4 pl-3">
        <button class="btn border-0 py-0 px-0" (click)="onToggleActive()"> 
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <div class="col d-flex justify-content-end font-size-14 px-0 h-100"> 
        <app-headbar-location></app-headbar-location>
      </div>
    </div>
    <div class="header-logo pl-3 text-left d-md-flex align-items-center d-none">
      <app-icon
        class="header-burger d-lg-inline d-none cursor-pointer"
        icon="burger"
        (click)="onToggleFold()"
      ></app-icon>
      <app-icon
        class="header-burger d-md-inline d-lg-none cursor-pointer"
        icon="burger"
        (click)="onToggleFoldMedium()"
      ></app-icon>
      <a routerLink="/" routerLinkActive="router-link-active" >
        <img src="/assets/images/logo/logo.svg" alt="" class="h-24px"/>
      </a>
    </div>
  </div>
  <div class="sidebar-body">
    <app-nested-menu
    [menu]="MENU_TREE"
    [hasGlovoIntegration]="this.hasGlovoIntegration"
    [userDetails]="userDetails"
  ></app-nested-menu>
  </div>
  
</div>
