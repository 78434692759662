<div
    class="position-relative w-315px bg-light-blue rounded-7 hover-wrapper
    px-4 pt-18px d-flex flex-column bg-bubbles bg-no-repeat bg-position-top-right box-shadow-variant-4 h-88px"
>
    <span class="font-color-white font-size-14 font-family-regular pb-2 line-h-normal">
        {{legalEntityData?.billingPackageDisplayName}}</span>
    <span class="font-color-white font-size-22 font-family-bold line-h-normal">
        {{legalEntityData?.monthPrice | number: '1.2-2':'en'}}
        <span class="font-size-15">₾
        </span> <span class="font-size-14 font-family-regular">/ {{ "GENERAL.PAYMENTS_BILLING.MONTH" | translate}}</span>
    </span>
    <div class="w-100 pt-3 position-absolute hover-element left-0px top-100pct"></div>

    <div class="position-absolute d-flex flex-column border bg-white bg-white p-4 w-485px hover-element
       z-index-10 left-0px top-100pct mt-3 border border-table-border-color rounded box-shadow-variant-3">
        <div class="w-100 d-flex align-items-center font-size-13 pb-3">
          <span class="col-6 px-0 font-family-bold">{{"GENERAL.PAYMENTS_BILLING.PACKAGE_TYPE" | translate }}</span>
          <span class="col-4 px-0 text-truncate"> {{legalEntityData?.billingPackageDisplayName}} </span>
          <span class="col-2 px-0 text-right" id="packagePrice"> {{legalEntityData?.packagePrice | number: '1.2-2':'en'}} ₾</span>
        </div>

        <div class="w-100 d-flex align-items-center font-size-13 pb-3">
          <span class="col-6 px-0 font-family-bold">{{"GENERAL.PAYMENTS_BILLING.ADDITIONAL_POINTS" | translate }}</span>
          <span class="col-4 px-0 text-truncate" id="pointsOfSale">{{ legalEntityData?.numberOfAdditionalPointsOfSale }}</span>
          <span class="col-2 px-0 text-right" id="sumPriceOfPontsOfSale">{{ legalEntityData?.numberOfAdditionalPointsOfSale ? ((legalEntityData?.numberOfAdditionalPointsOfSale * legalEntityData.additionalPointOfSalePrice) | number: '1.2-2':'en') : 0.00}} ₾</span>
        </div>

        <div class="w-100 d-flex align-items-center font-size-13 pb-3">
          <span class="col-6 px-0 font-family-bold">{{"GENERAL.PAYMENTS_BILLING.DEVICES" | translate }}</span>
          <span class="col-4 px-0 text-truncate" id="numberOfActiveDevices">{{ legalEntityData?.numberOfActiveDevices}}</span>
          <span class="col-2 px-0 text-right" id="sumPriceOfNumberOfActiveDevices">{{ legalEntityData?.numberOfActiveDevices ? ((legalEntityData?.numberOfActiveDevices * legalEntityData?.devicePrice) | number: '1.2-2': 'en') : 0.00}} ₾</span>
        </div>

        <div class="w-100 d-flex align-items-center font-size-13 pb-23px line-h-normal">
          <span class="col-6 px-0 font-family-bold">{{"GENERAL.PAYMENTS_BILLING.TOTAL_POINTS" | translate }}</span>
          <span class="col-4 px-0 text-truncate" id="totalPointsOfSale">{{ legalEntityData?.numberOfAdditionalPointsOfSale + 1}}</span>
          <span class="col-2 px-0"></span>
        </div>

        <div class="border-dashed dashed-top"></div>

        <div class="w-100 d-flex align-items-center font-size-13 pt-23px line-h-normal">
          <span class="col-6 px-0 font-family-bold">{{"GENERAL.PAYMENTS_BILLING.TOTAL_PACKAGE_AMOUNT" | translate }}</span>
          <span class="col-4 px-0 text-truncate"></span>
          <span class="col-2 px-0 text-right font-family-bold font-size-15" id="monthPrice">{{legalEntityData?.monthPrice | number :'1.2-2': 'en'}} ₾</span>
        </div>

    </div>
</div>



