import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientService } from '@optimo/core';
import { Observable, of } from 'rxjs';
import { INotification } from '../models/INotification';
import { INotificationListRequest } from '../models/INotificationListRequest';
import { INotificationListResponse } from '../models/INotificationListResponse';
import { ConfigService } from 'apps/dashboard/src/app/core/services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private _client: ClientService,
    private _configService: ConfigService,
  ) {}

  public markAllAsSeen(): Observable<void> {
    if (this._configService.getConfig()?.isAdmin) return of(null);
    return this._client.post(`notifications/seenall`);
  }

  public markAsRead(notification: INotification): Observable<void> {
    if (this._configService.getConfig()?.isAdmin) return of(null);
    return this._client.post(`notifications/read/${notification.id}`);
  }

  public getNotificationList(
    notificationListRequest: INotificationListRequest,
  ): Observable<INotificationListResponse> {
    if (this._configService.getConfig()?.isAdmin)
      return of({
        list: [],
        unseen: 0,
        total: 0,
        type: 0,
      });
    let httpParams = new HttpParams();
    Object.keys(notificationListRequest).forEach(function (key) {
      httpParams = httpParams.append(key, notificationListRequest[key]);
    });
    // httpParams = httpParams.append('type', 0);
    return this._client.get(`notifications`, {
      params: httpParams,
    });
  }
}
