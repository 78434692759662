import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EMonth, Months } from './date-month-picker';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

interface IMonth {
  value: EMonth;
  name: string;
}

@Component({
  selector: 'app-date-month-picker',
  templateUrl: './date-month-picker.component.html',
  styleUrls: ['./date-month-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateMonthPickerComponent implements OnInit, OnDestroy {
  private _isDatePickerVisible: any;
  public monthsList: IMonth[] = Months;
  public selectedMonth: any;
  public selectedYear: any;
  public currentYear: any;
  public currentMonth: any;
  public selectedDate: any;
  private unsubscribe$ = new Subject<void>();
  private toggleYear$ = new Subject();

  @Output()
  dateChange = new EventEmitter<Date | Date[]>();

  @Output()
  onIsDatePickerVisible = new EventEmitter<boolean>();

  private _defaultDate: any;
  @Input()
  set defaultDate(value) {
    this._defaultDate = value;
    if (!value) {
      this.selectedMonth = null;
      this.initDate();
    }
  }

  get defaultDate() {
    return this._defaultDate;
  }

  @Input()
  set isDatePickerVisible(value: boolean) {
    this._isDatePickerVisible = value;
    if (this.defaultDate === null) {
      this.initDate();
    }
  }

  get isDatePickerVisible(): boolean {
    return this._isDatePickerVisible;
  }

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.initDate();
    this.generateSelectedDate();
    this.toggleYear$
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.generateSelectedDate();
      });
  }

  private initDate() {
    const date = new Date();
    const month = date.getMonth() + 1;
    this.currentYear = date.getFullYear();
    this.selectedYear = this.currentYear;
    this.currentMonth = month;

    this._cdr.markForCheck();
  }

  private generateSelectedDate(changeDate = false) {
    const month = this.selectedMonth ? this.selectedMonth : this.currentMonth;
    this.selectedDate = new Date(this.selectedYear, month, 0);
    if (changeDate) {
      this.onDateChange();
    }
  }

  onToggleDatePicker(value?: boolean) {
    this.isDatePickerVisible =
      value === undefined ? !this.isDatePickerVisible : value;
    this.onIsDatePickerVisible.emit(this.isDatePickerVisible);
  }

  isDisabled(month: IMonth) {
    return (
      this.selectedYear === this.currentYear && month.value >= this.currentMonth
    );
  }

  onSelect(month: IMonth) {
    if (!this.isDisabled(month)) {
      this.selectedMonth = month.value;
      this.generateSelectedDate(true);
      this.isDatePickerVisible = !this.isDatePickerVisible;
    }
  }

  toggleYear(type: string) {
    this.toggleYear$.next(true);
    switch (type) {
      case 'up':
        if (this.selectedYear >= this.currentYear) {
          return;
        }
        this.selectedYear += 1;
        break;
      case 'down':
        this.selectedYear -= 1;
        break;
    }
    this.selectedMonth = this.selectedMonth
      ? this.selectedYear >= this.currentYear
        ? this.currentMonth - 1
        : this.selectedMonth
      : this.currentMonth;
  }

  private onDateChange(): void {
    this.dateChange.emit(this.selectedDate);
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
