<div class="table-component-inner-wrapper position-relative d-flex flex-column flex-grow-1" [class.table-has-no-data-or-loading]="!dataSource?.data?.length && !loading">
  <div
    [class.overflow-hidden]="overflow"
    class="table-content rounded d-flex flex-column wrapper flex-grow-1"
  >
    <table
      cdk-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="onSortChange($event)"
      [class.overflow-y-auto]="overflow"
      class="table mb-0 flex-shrink-0 table-layout-fixed"
      [class.table-loading-opacity]="loading"
      [class.table-placeholder]="!dataSource?.data?.length && !loading"
    >
      <ng-container *ngFor="let col of displayedColumns">
        <app-index-column
          *ngIf="col.columnType === columnType.Index"
          [length]="dataSource?.data?.length"
          [startIndex]="paginator?.pageIndex * paginator?.pageSize"
        ></app-index-column>

        <app-select-column
          *ngIf="col.columnType === columnType.Select"
          [actions]="actions"
          [selection]="selection"
          [hasInsideSelectAll]="hasInsideSelectAll"
          [isAllSelected]="isAllSelected"
          [selectableCustomEnable]="selectableCustomEnable"
          (rowToggle)="onRowSelectionToggle($event)"
          (selectAllToggle)="onSelectAllToggle()"
        ></app-select-column>

        <app-number-column
          *ngIf="col.columnType == columnType.Number"
          [col]="col"
          [state]="state"
          [cellTmpl]="getCellTmpl(col.dataField)"
          [headerCellTmpl]="getHeaderCellTmpl(col.dataField)"
          (changeFilter)="onChangeFilter($event)"
          (currencySwitch)="currencySwitch.emit($event)"
        ></app-number-column>

        <app-text-column
          *ngIf="col.columnType == columnType.Text"
          [col]="col"
          [state]="state"
          [cellTmpl]="getCellTmpl(col.dataField)"
          [headerCellTmpl]="getHeaderCellTmpl(col.dataField)"
          (changeFilter)="onChangeFilter($event)"
          (currencySwitch)="currencySwitch.emit($event)"
        ></app-text-column>

        <app-date-column
          *ngIf="col.columnType == columnType.Date || col.columnType == columnType.DateMonth"
          [col]="col"
          [state]="state"
          [cellTmpl]="getCellTmpl(col.dataField)"
          [headerCellTmpl]="getHeaderCellTmpl(col.dataField)"
          (changeFilter)="onChangeFilter($event)"
          (currencySwitch)="currencySwitch.emit($event)"
        ></app-date-column>

        <app-dropdown-column
          *ngIf="col.columnType == columnType.Dropdown"
          [col]="col"
          [state]="state"
          [dropdownKeyForStaticValueSelector]="dropdownKeyForStaticValueSelector"
          [cellTmpl]="getCellTmpl(col.dataField)"
          [headerCellTmpl]="getHeaderCellTmpl(col.dataField)"
          (changeFilter)="onChangeFilter($event)"
          (currencySwitch)="currencySwitch.emit($event)"
        ></app-dropdown-column>

        <app-actions-column
          *ngIf="col.columnType === columnType.Actions"
          [actions]="actions"
          [editingRow]="editingRow"
          [inlineEdit]="inlineEdit"
          (rowActivateToggle)="rowActivate.emit($event)"
          (rowEditToggle)="onRowEditToggle($event)"
          (rowEditSave)="onRowEditSave($event)"
          (rowDelete)="onRowDelete($event)"
          (rowViewAs)="onRowViewAs($event)"
          (showHideToggle)="onRowShowHide($event)"
        ></app-actions-column>

        <app-dropdown-multiselect-column
          *ngIf="col.columnType == columnType.DropdownMultiselect"
          [col]="col"
          [state]="state"
          [dropdownKeyForStaticValueSelector]="dropdownKeyForStaticValueSelector"
          [cellTmpl]="getCellTmpl(col.dataField)"
          [headerCellTmpl]="getHeaderCellTmpl(col.dataField)"
          (changeFilter)="onChangeFilter($event)"
          (currencySwitch)="currencySwitch.emit($event)"
        >
        </app-dropdown-multiselect-column>


      </ng-container>

      <ng-container *ngIf="!headerRowDefTmpl">
        <tr cdk-header-row *cdkHeaderRowDef="displayedColumnNames"></tr>
      </ng-container>
      <ng-container *ngIf="filterable">
        <tr
          cdk-header-row
          *cdkHeaderRowDef="displayedColumnFilterNames"
        ></tr>
      </ng-container>

      <ng-container *ngIf="!rowDefTmpl">
        <tr
          cdk-row
          *cdkRowDef="let row; columns: displayedColumnNames"
          [class.selected]="rowIsSelected(row)"
          [class.hidden]="rowIsHidden(row)"
          (click)="onRowClick(row)"
        ></tr>
      </ng-container>
    </table>
    <div *ngIf="loading" class="loading">
        <app-icon icon="loader-big"></app-icon>
    </div>
    <div
      *ngIf="!dataSource?.data?.length && !loading && emptyData"
      class="table-no-data"
    >
      <app-icon icon="no-data"></app-icon>
      <span class="font-size-14 mt-14px color-dark font-family-bold">
          {{'TABLE.DATA_IS_EMPTY' | translate}}
      </span>
    </div>
  </div>

  <app-col-visibility-selector
    class="custom-select-for-columns-wrapper"
    *ngIf="hasColumnSelector"
    [columns]="displayedDateColumns"
  ></app-col-visibility-selector>
</div>
<!-- viewportUnderSm this variable is just for showing this element under 768px and with static position,after making all pages responsive this should be in only one place now is in two place-->

<ng-container *ngIf="showTotals && dataSource?.data?.length!==0">
  <!-- <div class="table-summary-row-wrapper"> -->
    <div class="table-summary-row">
      <div class='d-table-row'>
        <ng-container *ngFor="let col of displayedColumns; let i=index;">
          <ng-container *ngIf="col.columnType !== columnType.Actions
              && !col.hidden
              && col.columnType !== columnType.Index"
          >
            <div
              class="summary-cell"
              [class.cdk-column-select]="col.columnType === columnType.Select"
              [style.width.%]="col.widthPercent"
              [style.text-align]="i==(selectable ? 1 : 0) ? 'left' : 'right'"
            >
              <div
                class="text-truncate"
                matTooltipClass="mat-tooltip-position-below"
                [matTooltip]="_totals[col.dataField]
                 ? ((_totals[col.dataField]['tooltip'] | translate)
                 + (!textIsTruncated(ref1) ? ': '
                 + (_totals[col.dataField]['total']  | currencyPipe : true)
                 + ' ' +(_totals[col.dataField]['currency'] ? _totals[col.dataField]['currency'] : (UI_CONFIG.currencyLabel)) : '' ))
                 : ''"
              >
                <span class="text-truncate font-family-bold font-size-14" #ref1>
                 <ng-container *ngIf="i==(selectable ? 1 : 0); else defaultValue"> {{'GENERAL.TOTAL' | translate}} </ng-container>
                  <ng-template #defaultValue>
                   <ng-container *ngIf="_totals[col.dataField]">
                    {{(_totals[col.dataField]['total'] ? (_totals[col.dataField]['total']) : 0 ) | currencyPipe : true }}
                     <ng-container *ngIf="_totals[col.dataField]['currency'] !== null">
                       {{_totals[col.dataField]['currency'] ? _totals[col.dataField]['currency'] : (UI_CONFIG.currencyLabel)}}
                     </ng-container>
                   </ng-container>
                 </ng-template>
               </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  <!-- </div> -->
</ng-container>

<div
  *ngIf="!_externalPaginator && paginable"
  class="table-footer"
>
  <div class="py-6px px-4">
    <app-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="totalCount"
      showFirstLastButtons="false"
    ></app-paginator>
  </div>
</div>
<ng-container *ngIf="inlineEdit">
  <ng-container *ngFor="let col of displayedDateColumns">
    <ng-template app-cell let-row="row" let-cell="cell" [name]="col.dataField">
      <ng-container *ngIf="isFieldEditable(row, col.dataField)">
        <mat-form-field>
          <input
            data-hj-allow
            matInput
            [value]="cell"
            [formControl]="getFormControl(col.dataField)"
        /></mat-form-field>
      </ng-container>
      <ng-container *ngIf="!isFieldEditable(row, col.dataField)">
        {{ cell }}
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
