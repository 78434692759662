<app-column [dataField]="col.dataField"  [isOpen]="isOpen" [caption]="col.caption" [col]="col" [asyncCaption]="col.asyncCaption" [sortable]="col.sortable"
  [widthPercent]="col.widthPercent" (cellClick)="cellClick.emit($event)" (currencySwitch)="currencySwitch.emit($event)">
  <ng-template *ngIf="headerCellTmpl" #headerCell>
    <ng-container *ngTemplateOutlet="headerCellTmpl"></ng-container>
  </ng-template>

  <ng-template #cell let-row="row">
    <ng-container *ngTemplateOutlet="
        cellTmpl;
        context: { cell: row[col.dataField], row: row }
      "></ng-container>
    <div *ngIf="!cellTmpl" [ngClass]="col.className" class="td-content-wrapper d-flex mw-100">
      <span  [matTooltip]="getStaticPermissionsData(col, row) | translate" class="text-dark" #ref1 [matTooltipDisabled]="textIsTruncated(ref1)">
        {{ getStaticPermissionsData(col, row) | translate}}
      </span>
    </div>
  </ng-template>

  <ng-template *ngIf="col.filterable" #filterCell>
    <div class="d-flex align-items-center h-100" (click)="onToggleFilter($event);  isOpen=!isOpen">
      <input
        data-hj-allow type="text"
        class="table-filter-input"
        [placeholder]="'TABLE.SELECT' | translate" readonly
        [id]="'table-header-dropdown-'+col.dataField"
        [value]="(col.data[selectedKey] | translate) || ''"
        />
      <app-icon [icon]="'arrow-down'" [ngClass]="{'visibility-hidden pointer-events-none' : selectedKey}" class="th-icon cursor-pointer"></app-icon>
    </div>
    <button *ngIf="selectedKey" type="reset" class="filter-reset outline-none" (click)="filterForm.reset(); onSubmit(true)">
      <app-icon icon="filter-reset"></app-icon>
    </button>
    <div *ngIf="isFilterVisible" clickOutside (clickOutside)="onToggleFilter();  isOpen=!isOpen"
      class="filter-dialog active">
      <ng-container *ngFor="let key of objectKeys(col.data); let i=index;">
        <div
          class="dropdown-item  "
          [id]="'table-header-dropdown-option-'+col.dataField + '-' + i"
          [class.active]="selectedKey === key"
          (click)="onSelectionChange(key)"
        >
          <span class="text-truncate font-family-regular"> {{ col.data[key] | translate }} </span>
        </div>
      </ng-container>
    </div>
  </ng-template>
</app-column>
