import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMessageDialogComponent } from './payment-message-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '@optimo/ui-icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApproveDialogModule } from '@optimo/ui-popups-approve-dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PaymentMessageDialogComponent],
  exports: [PaymentMessageDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ApproveDialogModule,
    IconModule,
    MatTooltipModule,
    TranslateModule.forChild(),
  ],
})
export class PaymentMessageDialogModule {}
