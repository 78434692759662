  <div
    class="bg-white rounded mat-dialog-boxshadow"
  >
    <div
    class="d-flex align-items-center justify-content-between border-bottom border-table-border-color pl-sm-4 pl-3 pr-sm-18px pr-2 h-60px"
    >
      <span class="font-color-dark font-size-18 font-family-bold line-h-normal" >
      თქვენ გამოხვედით სისტემიდან
      </span>
      <button
      class="btn bg-transparent rounded-0 border-0 p-6px d-flex justify-content-center align-items-center"
      (click)="onClose()"
      >
        <app-icon icon="close"></app-icon>
      </button>
    </div>
    <div class="pt-4 px-4 font-color-dark font-size-14 font-family-regular line-h-normal">
        უმოქმედობის გამო თქვენ გამოხვედით სისტემიდან.
    </div>
    <div class="d-flex justify-content-end align-items-center p-4">
      <button
      class="btn btn-primary font-color-white font-size-14 font-family-bold w-105px h-42px"
      (click)="onClose()"
      >
      შესვლა
      </button>
    </div>
  </div>
