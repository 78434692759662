<div class="filter-dialog filter-dialog-type-number active">
  <form [formGroup]="filterForm">
    <div class="form-group pt-3 mb-3">
      <div class="row mx-0">
        <div class="col pl-3 pr-0">
          <label
          for="numberFilterFrom"
          class="font-family-bold font-size-14 font-color-dark line-h-normal mb-2"

          >{{'TABLE.MIN_SHORT' | translate}}</label
        >
        </div>
        <div class="col pr-3 pl-0">
          <label
            for="numberFilterTo"
            class="font-family-bold font-size-14 font-color-dark line-h-normal mb-2"

            >{{'TABLE.MAX_SHORT' | translate}}</label
          >
        </div>
      </div>
        <div class="w-100 px-3  d-flex">
          <input
            data-hj-allow
            id="numberFilterFrom"
            type="number"
            class="form-control rounded  form-control-sm input-left-custom-focus col h-42px"
            [placeholder]="'TABLE.FILL_IN' | translate"
            autocomplete="off"
            formControlName="from"
            (ngModelChange)="from = $event"
          />
          <input
            data-hj-allow
            id="numberFilterTo"
            type="number"
            class="form-control rounded   form-control-sm input-right-custom-focus col h-42px"
            [placeholder]="'TABLE.FILL_IN' | translate"
            autocomplete="off"
            formControlName="to"
            (ngModelChange)="to = $event"
          />
        </div>
        <div class="col pr-3 pl-0">


        </div>

    </div>

    <div class="px-3 pb-3 w-100 d-sm-block d-none" *ngIf="lowStock">
      <div class="custom-radio-wrapper pb-3">
        <input
          data-hj-allow
          type="radio"
          id="all"
          formControlName='getLowStockOnly'
          (click)="stock = 'false' "
          value="">
        <label
          class="font-size-14 font-color-dark font-family-regular line-h-normal custom-radio-label pl-26px font-weight-normal pb-2px"
          for="all"
        >{{'TABLE.ALL' | translate}}</label
        >
      </div>
        <div class="custom-radio-wrapper">
        <input
          data-hj-allow
          type="radio"
          id="minStock"
          formControlName='getLowStockOnly'
          (click)="stock = 'true' "
          value="true">
        <label
          class="font-size-14 font-color-dark font-family-regular line-h-normal custom-radio-label pl-26px font-weight-normal pt-2px"
          for="minStock"
        >{{'TABLE.MIN_STOCK' | translate}}</label
        >
      </div>  
    </div>

    <div class="w-100 px-3 pb-3">


        <button
          [disabled]="
            ((!from && from !== 0 && !to && to !== 0) ||
            (from && to && from > to)) &&
            !lowStock
          "
          type="submit"
          [id]="'table-header-range-picker-confirm-btn'"
          class="btn btn-primary btn-sm btn-block px-0 w-100 font-family-bold font-color-white font-size-14 h-42px"
        >
          {{'TABLE.CONFIRM' | translate}}
        </button>

    </div>
  </form>
</div>
