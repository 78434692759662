import { Injectable } from '@angular/core';
import { ClientService, Service } from '@optimo/core';
import { catchError, map, startWith } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import {
  BillingStatus,
  legalEntityDataBilling,
} from '../../../pages/auth/billing-payments/packages-card/packages-card.component';

@Injectable({
  providedIn: 'root',
})
export class BillingPaymentServiceService {
  public legalEntityData: BehaviorSubject<legalEntityDataBilling> =
    new BehaviorSubject(null);
  public bankCards: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public showPayments: Observable<boolean> = this.legalEntityData.pipe(
    map((item) => item && item?.billingStatus !== BillingStatus.NonActive),
    startWith(false),
  );

  constructor(private client: ClientService) {}

  public legalEntitiesBillingInfo(): void {
    this.client
      .get<any>('legalentities/dashboard', {
        service: Service.Billing,
      })
      .pipe(
        catchError(() => {
          return EMPTY;
        }),
      )
      .subscribe((item) => {
        this.legalEntityData.next(item);
      });
  }
}
