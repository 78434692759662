<ng-container *ngIf="billingItem$ | async as billingItem">
  <div
    *ngIf="billingItem?.billingStatus !==  billingStatus.NonActive"
    class="position-sm-relative d-flex align-items-center h-100"
    [class.active]="isExpanded"
  >
    <div (click)='open($event)' class="d-flex align-items-center cursor-pointer h-100">
      <app-icon
      icon="wallet"
      class="mr-3 mr-sm-4 mr-md-2"
      
      >
      </app-icon>
      <span class="font-family-bold font-size-14 text-dark text-truncate d-none d-md-inline">{{"GENERAL.PAYMENTS_BILLING.BALANCE" | translate }}</span>
      <app-icon
        icon="arrow-down"
        class="flex-shrink-0 d-none d-md-inline ml-1"
      ></app-icon>

      <div
      *ngIf="isExpanded"
      class="header-dropdown w-236px p-0"
      clickOutside
      (clickOutside)="close($event)"
    >
      <div class="d-flex flex-column">
        <div class="d-flex w-100 align-items-center justify-content-between pl-3 pt-12px pr-4 pb-3 bg-sidebar-gray">
          <div class="font-color-dark line-h-normal">
            <div class="font-size-12 font-family-regular pb-1">{{"GENERAL.PAYMENTS_BILLING.BALANCE_ID" | translate}}</div>
            <div class="font-size-16 font-family-bold">{{ billingItem.balanceId.toString() | padString:'0':5}}</div>
          </div>
          <button class="btn p-0 bg-transparent"
                  (click)="copyValue(billingItem.balanceId)"
                  [matTooltip]="('GENERAL.PAYMENTS_BILLING.COPY' | translate)">
            <app-icon icon="copy-value"></app-icon>
          </button>
        </div>
        <div class="d-flex w-100 align-items-center justify-content-between pl-3 pt-12px pr-4 pb-3">
          <div class="font-color-dark line-h-normal">
            <div class="font-size-12 font-family-regular pb-1">{{"GENERAL.PAYMENTS_BILLING.BALANCE" | translate}}</div>
            <div class="font-size-16 font-family-bold">{{ billingItem?.balanceAmount | number: '1.2-2':'en'}} ₾</div>
          </div>
          <div class="font-color-dark line-h-normal">
            <div class="font-size-12 font-family-regular pb-1">{{"GENERAL.PAYMENTS_BILLING.DEBT" | translate}}</div>
            <div class="font-size-16 font-family-bold font-color-red">{{ billingItem?.dueAmount | number: '1.2-2':'en'}} ₾</div>
          </div>
        </div>
        <div *ngIf="hasCards" class="border-top border-input-border-gray d-flex align-items-center pt-14px pb-3 pl-12px" (click)="onFillBalance()">
            <app-icon icon="plus-blue">
            </app-icon>
            <span class="font-color-blue font-family-bold font-size-14 pl-2">{{"GENERAL.PAYMENTS_BILLING.TOP_UP_THE_BALANCE" | translate}}</span>
        </div>
      </div>
    </div>
    </div>
  </div>
</ng-container>






