<div
  *ngIf='showPaginator'
  class='container-fluid'
>
  <div class='row align-items-center table-footer-inner-wrapper'>
    <ng-container *ngIf='!hidePageSize'>
      <ng-select
        id="table-page-size-selector"
        class='form-control border border-input-border-gray ng-option-centered p-0 w-68px h-34px table-footer-items-per-page-select'
        [ngModel]='pageSize'
        [disabled]="disablePageSizeSelect"
        [items]='pageSizeOptions'
        (change)='changePageSize($event)'
        [searchable]='false'
        [clearable]='false'
      >
      </ng-select>

      <div class='ml-10px font-family-regular font-size-14 font-color-dark table-footer-page-quantity'>
        / {{ length }}
      </div>
    </ng-container>
    <nav class='ml-sm-auto d-flex align-items-center justify-content-center table-footer-page-navigation'>
      <button
        *ngIf='showNextPrevButtons'
        (click)='goTo(pageIndex)'
        [disabled]='!hasPreviousPage()'
        class='btn bg-transparent'
        id="table-paginator-prev-btn"
      >
        <span class='font-size-12 text-muted'>{{'TABLE.PREV' | translate}}</span>
      </button>
      <ul class='pagination justify-content-center mb-0'>
        <ng-container *ngFor='let page of shownPages'>
          <li
            class='page-item text-center'
            [class.active]='pageIndex + 1 === page'
            [class.dots]='page < 0'
            [id]="'table-page-item-' + page"
          >
            <a
              *ngIf='page > 0'
              class='page-link position-relative'
              href='javascript: void(0)'
              (click)='goTo(page)'
              [id]="'table-page-link-' + page"
            >
              <span class='line-h-normal'> {{ page }} </span>
            </a>
            <ng-container *ngIf='page < 0'>...</ng-container>
          </li>
        </ng-container>
      </ul>
      <button
        *ngIf='showNextPrevButtons'
        (click)='goTo(pageIndex + 2)'
        [disabled]='!hasNextPage()'
        class='btn bg-transparent'
        id="table-paginator-next-btn"
      >
        <span class='font-size-12 text-muted'>{{'TABLE.NEXT' | translate}}</span>
      </button>
    </nav>
    <ng-container *ngIf='!hidePageSize || canSelectPage'>
      <div
        class='mr-2 ml-2 font-family-regular font-size-14 border-left pl-4 border-table-border-color font-color-dark table-footer-choose-page-text'
      >
        {{'TABLE.SELECT_PAGE' | translate}}
      </div>
      <ng-select
        id="table-page-index-selector"
        class='form-control border border-input-border-gray no-arrow p-0 ng-option-centered w-65px h-34px table-footer-choose-index-select'
        [ngModel]='pageIndex + 1'
        [items]='pagesArray?.length!==0 ? pagesArray : [pageIndex + 1]'
        (change)='goTo($event)'
        [searchable]='false'
        [clearable]='false'
      >
      </ng-select>
    </ng-container>
  </div>
</div>
