import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsDialogComponent } from './payment-methods-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '@optimo/ui-icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApproveDialogModule } from '@optimo/ui-popups-approve-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentMethodsCardComponent } from './payment-methods-card/payment-methods-card.component';
import { TableModule } from '@optimo/ui-table';

@NgModule({
  declarations: [PaymentMethodsDialogComponent, PaymentMethodsCardComponent],
  exports: [PaymentMethodsDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ApproveDialogModule,
    IconModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    TableModule,
  ],
})
export class PaymentMethodsDialogModule {}
