import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateMonthPickerComponent } from './date-month-picker.component';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@optimo/ui-icon';
import { ClickOutsideModule } from '@optimo/util-click-outside';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DateMonthPickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    ClickOutsideModule,
    TranslateModule.forChild(),
  ],
  exports: [DateMonthPickerComponent],
})
export class DateMonthPickerModule {}
