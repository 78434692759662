import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/dashboard/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  public logError(error: Error | HttpErrorResponse): void {
    if (!environment.production) {
      console.log(error);
    }
  }
}
