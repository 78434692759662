import { CustomValidators } from '../app/core/helpers/validators/validators.helper';
import { IOptimoDashboardMenuItem } from '../app/layouts/authorized-layout/sidebar/nested-menu.mock';
import { EOptimoProductType } from '@optimo/core';
import { SaleOrderPaymentTypeRetail } from '../app/core/enums/sale-orders-payment-types-retail.enum';
import { HistoryItemTypes } from '../app/core/enums/history-item-types.enum';
import { ShippingStatuses } from '../app/core/enums/shipping-status.enum';
import {
  arrayOflanguagesMap,
  ELanguage,
} from '../app/core/enums/langauge.enum';
import { Validators } from '@angular/forms';
import { AttributeTypeNameUz } from '../app/core/enums/attributes.enum';
import { HistoryPaymentMethods } from '../app/core/enums/history-payment-methods.enum';

export const isGe = false;
export const isUz = true;

export const currencyLabel = 'UZS';
export const currencyFormatDigits = 2;
export const currencyFormat = `1.${currencyFormatDigits}-${currencyFormatDigits}`;
export const currencyFormatDigitsShort = 2;
export const currencyFormatShort = `1.${currencyFormatDigitsShort}-${currencyFormatDigitsShort}`;
export const phoneNumberValidator = CustomValidators.UzPhoneNumber;

export const idNumberLength = 9;
export const idNumberValidator = [
  CustomValidators.OnlyNumbers,
  CustomValidators.exactLength(idNumberLength),
];
export const entityIdNumberLength = 9;
export const entityIdNumberValidator = [
  CustomValidators.OnlyNumbers,
  CustomValidators.exactLength(entityIdNumberLength),
];

export const entityOrIndividualIdNumberValidator = [
  CustomValidators.OnlyNumbers,
  Validators.pattern(/^[0-9]{9}$/),
];

export const MENUTREE: IOptimoDashboardMenuItem[] = [
  {
    path: '/dashboard',
    text: 'GENERAL.MAIN',
    icon: 'dashboard',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: true,
  },
  {
    text: 'GENERAL.PRODUCTS',
    icon: 'product',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: false,
    children: [
      {
        path: '/inventory',
        text: 'GENERAL.PRODUCTS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stockitems_products',
      },
      {
        path: '/categories',
        text: 'GENERAL.CATEGORIES',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stockitemcategories',
      },
      {
        path: '/shippings',
        text: 'GENERAL.TRANSPORTATIONS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stocktransferorders',
      },
      {
        path: '/inventorisations',
        text: 'GENERAL.INVENTORISATION',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stocktakeorders',
      },

      {
        path: '/priceType',
        text: 'GENERAL.PRICETYPE',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'pricetypes',
      },
    ],
  },
  {
    text: 'GENERAL.SUPPLIERS',
    icon: 'suppliers',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: false,
    children: [
      {
        path: '/suppliers',
        text: 'GENERAL.SUPPLIERS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'suppliers',
      },
      {
        path: '/suppliers-history',
        text: 'GENERAL.HISTORY',
        pageName: 'GENERAL.SUPPLIERS_HISTORY',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        translateId: '',
        permissionName: 'suppliers_balances',
      },
    ],
  },
  {
    text: 'GENERAL.PURCHASES',
    icon: 'orders',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: false,
    children: [
      {
        path: '/orders',
        text: 'GENERAL.PURCHASES',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'purchaseorders',
      },
      {
        path: '/history/lots',
        text: 'GENERAL.LOTS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'warehouse_stockholdinglotshistory',
      },
    ],
  },
  {
    text: 'GENERAL.ENITYT_SALES',
    icon: 'entity-sales',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: false,
    decisionField: 'isEntitySaleEnabled',
    children: [
      {
        path: '/entity-sales',
        text: 'GENERAL.SALES',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'entitysaleorders',
      },
      {
        path: '/entity-clients',
        text: 'GENERAL.BUYERS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'entityclient',
      },
      {
        path: '/payment-history',
        text: 'GENERAL.HISTORY',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'entityclient_balances',
      },
    ],
  },
  {
    text: 'GENERAL.PRODUCTION',
    icon: 'manufacture',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: false,
    children: [
      {
        path: '/ingredients',
        text: 'GENERAL.INGREDIENTS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stockitems_ingredients',
      },
      {
        path: '/receipt-templates',
        text: 'GENERAL.RECEIPT_TEMPLATES',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stockitemreceipttemplate',
      },
      {
        path: '/receipts',
        text: 'GENERAL.RECEIPTS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stockitemreceipt',
      },
      {
        path: '/production-orders',
        text: 'GENERAL.PRODUCTION_ORDERS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        permissionName: 'stockitemproductionorders',
      },
    ],
  },
  {
    text: 'GENERAL.ACCOUNTING',
    icon: 'stockholdings',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    isVisibleForAllLocations: false,
    children: [
      {
        path: '/history/sales',
        text: 'GENERAL.SALES',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'sales_salehistory',
      },
      {
        path: '/stockholdings',
        text: 'GENERAL.STOCK_HOLDINGS',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'warehouse_stockholdings',
      },
    ],
  },
  {
    text: 'GENERAL.REPORTS',
    icon: 'history',
    isVisibleForAllLocations: true,
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    children: [
      {
        path: '/reports/sale-orders',
        text: 'GENERAL.TRANSACTIONS',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'saleorders',
      },
      {
        path: '/reports/stock-movement',
        text: 'GENERAL.STOCK_MOVEMENT',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'warehouse_inventorychangehistory',
      },
      {
        path: '/reports/supplies',
        isVisibleForAllLocations: true,
        text: 'GENERAL.SUPPLIES',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'stockitems_transactions',
      },
      {
        path: '/reports/prices',
        text: 'GENERAL.PRICES',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'warehouse_stockitempricehistory',
      },
      {
        path: '/reports/withdrawals',
        text: 'WithdrawalsReport.withDrawDepositTitle',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'sales_cashmovements',
      },
      {
        path: '/reports/operators',
        text: 'GENERAL.SHIFTS',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'sales_salesoperatorsshifts',
      },
      {
        path: '/reports/pos-log',
        text: 'POS_REPORT.POS_LOG',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
      },
    ],
  },
  {
    text: 'GENERAL.STATISTICS',
    icon: 'statistics',
    isVisibleForAllLocations: true,
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    children: [
      {
        path: '/statistics/general',
        text: 'GENERAL.GENERAL',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        pageName: 'ზოგადი სტატისტიკა',
        translateId: 'GENERAL.GENERAL',
        permissionName: 'warehouse_statistics_general',
      },
      {
        path: '/statistics/products',
        text: 'GENERAL.PRODUCTS',
        isVisibleForAllLocations: true,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        pageName: 'პროდუქტების სტატისტიკა',
        permissionName: 'warehouse_statistics_stockitem',
      },
      {
        path: '/statistics/categories',
        isVisibleForAllLocations: true,
        text: 'GENERAL.CATEGORIES',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        pageName: 'კატეგორიების სტატისტიკა',
        permissionName: 'warehouse_statistics_categories',
      },
    ],
  },
  // {
  //   path: '/catalogue',
  //   text: 'GENERAL.CATALOGUE',
  //   isVisibleForAllLocations: true,
  //   icon: 'catalogue',
  //   productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
  // },
  {
    isVisibleForAllLocations: false,
    text: 'GENERAL.ADMINSTRATION',
    icon: 'settings',
    productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
    children: [
      {
        path: '/profile',
        text: 'GENERAL.PROFILE',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'user_getuserdetails',
      },
      {
        path: '/operators',
        text: 'GENERAL.WORKERS',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'operators',
      },
      {
        path: '/locations',
        text: 'GENERAL.BRANCHES',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        permissionName: 'locations',
      },
      {
        path: '/tables',
        text: 'GENERAL.TABLES',
        isVisibleForAllLocations: false,
        productTypes: [EOptimoProductType.HORECA],
      },
      {
        path: '/sub-users',
        text: 'GENERAL.SUB_USERS',
        productTypes: [EOptimoProductType.Retail, EOptimoProductType.HORECA],
        isVisibleForAllLocations: false,
        allowedRoles: ['BO'],
        permissionName: 'user_staff',
      },
    ],
  },
];
export const HistoryPaymentMethodsMap = {
  [HistoryPaymentMethods.Card]: 'SUPPLIERS.PAYMENT_METHODS.CARD',
  [HistoryPaymentMethods.Cash]: 'SUPPLIERS.PAYMENT_METHODS.CASH',
  [HistoryPaymentMethods.PrePayment]: 'SUPPLIERS.PAYMENT_METHODS.PRE_PAYMENT',
  [HistoryPaymentMethods.Consignation]:
    'SUPPLIERS.PAYMENT_METHODS.CONSIGNATION',
  [HistoryPaymentMethods.Other]: 'SUPPLIERS.PAYMENT_METHODS.OTHER',
};
export const SaleOrderPaymentTypeRetailMap = {
  [SaleOrderPaymentTypeRetail.Cash]: 'SaleOrdersHistory.PAYMENT_TYPES.CASH',
  [SaleOrderPaymentTypeRetail.Click]: 'SaleOrdersHistory.PAYMENT_TYPES.CLICK',
  [SaleOrderPaymentTypeRetail.Payme]: 'SaleOrdersHistory.PAYMENT_TYPES.PAYME',
  [SaleOrderPaymentTypeRetail.Manual]:
    'SaleOrdersHistory.PAYMENT_TYPES.MANUAL_2',
  [SaleOrderPaymentTypeRetail.Advance]:
    'SaleOrdersHistory.PAYMENT_TYPES.ADVANCE',
  [SaleOrderPaymentTypeRetail.Consignation]:
    'SaleOrdersHistory.PAYMENT_TYPES.CONSIGNATION',
  [SaleOrderPaymentTypeRetail.Other]: 'SaleOrdersHistory.PAYMENT_TYPES.OTHER',
};
export const SaleOrderPaymentTypeRetailTableColumnsMap = {
  ['cashAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.CASH',
  ['cardAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.CARD',
  ['clickAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.CLICK',
  ['paymeAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.PAYME',
  ['manualAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.MANUAL_2',
  ['advanceAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.ADVANCE',
  ['consignationAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.CONSIGNATION',
  ['otherAmount']: 'SaleOrdersHistory.PAYMENT_TYPES.OTHER',
};
export const HistoryItemTypesMap = {
  [HistoryItemTypes.Purchase]: 'GENERAL.PURCHASE',
  [HistoryItemTypes.Payment]: 'ENTITY_CLIENTS.TRANSACTIONS.PAYMENT',
};

export const profileTabs = [
  {
    path: 'personal-information',
    text: 'GENERAL.PROFILE',
    event: '[Profile] Profile (click)',
  },
  {
    path: 'change-password',
    text: 'PROFILE.CHANGE_PASSWORD',
    event: '[Profile] change password (click)',
  },
];
export const ShippingStatusMap = {
  [ShippingStatuses.Draft]: 'Shipping.STATUS_LIST.DRAFT',
  [ShippingStatuses.Shipped]: 'Shipping.STATUS_LIST.SHIPPED',
};
export const ShippingDetailsentityIdentifierMask = `0{${idNumberLength}}`;
export const ShippingDetailsCitizenships = [
  { value: false, label: 'ENTITY_SALES.VIEW.DRIVER_CITIZENSHIP_VALUES.UZ' },
  { value: true, label: 'ENTITY_SALES.VIEW.DRIVER_CITIZENSHIP_VALUES.OTHER' },
];
export const phoneNumberMinLength = 9;
export const phoneNumberMaxLength = 9;

export const vatTypeStandard = 12;
export const arrayOflanguages = [
  arrayOflanguagesMap[ELanguage.en],
  arrayOflanguagesMap[ELanguage.ru],
];

export enum AttributeTypeName {
  Color = AttributeTypeNameUz.Color,
  Size = AttributeTypeNameUz.Size,
}

export const entityIdentifierMask = '0{9}';
