<div class="notifications-wrapper pr-sm-4 pr-3" clickOutside (clickOutside)="clickOutside()">
  <button [class.active]="showList"
    class="btn p-0 notification-btn rounded-circle d-flex justify-content-center align-items-center border-0"
    (click)="toggleNotificationList()">
    <app-icon icon="notification" matTooltip='შეტყობინებები'></app-icon>
    <div *ngIf="unseenNotificationQuantity$ | async as unseenNotificationQuantity" class="notification-number">
      {{unseenNotificationQuantity}}</div>
    </button>
  <app-notification-list *ngIf="showList" [notifications]="notifications" (onScroll)="onScroll()"  (openNotification)="openNotification($event)"></app-notification-list>
</div>
