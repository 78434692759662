import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  BillingStatus,
  legalEntityDataBilling,
} from '../../../../pages/auth/billing-payments/packages-card/packages-card.component';
import { padString } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { BillingPaymentServiceService } from '../../../../core/services/billing-payment/billing-payment-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceComponent implements OnInit {
  isExpanded: boolean;
  billingStatus = BillingStatus;
  billingItem$: Observable<legalEntityDataBilling> =
    this.billingService.legalEntityData;
  billingCards$: Observable<any> = this.billingService.bankCards;
  public hasCards = false;
  constructor(
    private _router: Router,
    private translateService: TranslateService,
    private clipboard: Clipboard,
    private billingService: BillingPaymentServiceService,
  ) {}

  ngOnInit() {
    this.billingCards$.subscribe((cards) => {
      if (cards !== null) {
        this.hasCards = !!cards.length;
      }
    });
  }

  copyValue(value: number) {
    this.clipboard.copy(padString(value.toString(), 5, '0'));
  }

  close(event) {
    this.isExpanded = false;
    event.stopPropagation();
    event.preventDefault();
  }

  open(event) {
    this.isExpanded = !this.isExpanded;
    event.stopPropagation();
    event.preventDefault();
  }

  onFillBalance() {
    this._router.navigate(['billing-payments/transactions'], {
      queryParams: this.hasCards
        ? { showBillingBalanceModal: true }
        : { showBankAccountModal: true },
    });
  }
}
