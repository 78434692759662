import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePopupComponent {
  constructor(private dialogRef: MatDialogRef<MessagePopupComponent>) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
