export enum SaleOrderPaymentTypeRetail {
  Cash = 0,
  BOG = 1,
  BOGExternal = 2,
  TBCExternal = 3,
  LibertyExternal = 4,
  ProcreditExternal = 5,
  Click = 6,
  Payme = 7,
  Keepz = 9,
  Consignation = 10,
  Advance = 11,
  Manual = 98,
  Other = 99,
}

export const SaleOrderPaymentMethod = {
  1: 'SaleOrdersHistory.PAYMENT_METHODS.CASH',
  2: 'SaleOrdersHistory.PAYMENT_METHODS.CARD',
  3: 'SaleOrdersHistory.PAYMENT_METHODS.MANUAL_2',
  4: 'SaleOrdersHistory.PAYMENT_METHODS.COMBINED',
};
export const SaleOrderPaymentMethodUz = {
  ...SaleOrderPaymentMethod,
  5: 'SaleOrdersHistory.PAYMENT_METHODS.ADVANCE',
  6: 'SaleOrdersHistory.PAYMENT_METHODS.CONSIGNATION',
};

export const SaleOrderStatusData = {
  5: 'SaleOrdersHistory.STATUS_LIST.SUCCEEDED',
  95: 'SaleOrdersHistory.STATUS_LIST.RETURNED',
};
