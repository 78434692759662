import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInCalendarDays, endOfDay } from 'date-fns';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { DateManipulation } from './date-manipulation';
import { ICustomDateRanges } from './ICustomDateRanges';
import { Georgian } from './ka';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';
import { NotificationsService } from '../../../../../apps/dashboard/src/app/core/services/notifications/notifications.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent {
  @ViewChild('datepicker', { static: true })
  datepicker: ElementRef;
  @Input()
  customRange: ICustomDateRanges[] = [];
  @Input() rangeIsLimited = false;
  @Input()
  isRangePicker = true;

  @Input()
  defaultDate: Date | Date[];

  @Input()
  maxDate: Date;

  @Input()
  minDate: Date;

  @Input()
  isMonthSelect: boolean;

  @Input()
  showMonths = 1;

  @Input()
  showActionsAndInput = true;

  @Input()
  showOnlyDays = false;

  @Input()
  rangeLimitDays: number;

  @Input()
  rangeLimitErrorMessage: string;

  private _isDatePickerVisible: boolean;

  @Input()
  set isDatePickerVisible(value: boolean) {
    this._isDatePickerVisible = value;
    if (value) {
      this.initializeDatePicker();
    } else {
      if (this.datePickerEl && this.zone) {
        this.zone.runOutsideAngular(() => {
          this.datePickerEl.destroy();
        });
      }
    }
  }

  get isDatePickerVisible(): boolean {
    return this._isDatePickerVisible;
  }

  @Output()
  dateChange = new EventEmitter<Date | Date[]>();

  @Output()
  onIsDatePickerVisible = new EventEmitter<boolean>();

  myCustomRanges: ICustomDateRanges[];
  lang = this.translate.currentLang;
  private datePickerEl: flatpickr.Instance;

  constructor(
    private notificator: NotificationsService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  isActive(ranges: Date[]) {
    return JSON.stringify(this.defaultDate) === JSON.stringify(ranges);
  }

  onToggleDatePicker(value?: boolean) {
    this.isDatePickerVisible =
      value === undefined ? !this.isDatePickerVisible : value;
    this.onIsDatePickerVisible.emit(this.isDatePickerVisible);
  }

  goToNextOrPrev(next: boolean) {
    const newSelectedDate = new DateManipulation(
      this.defaultDate,
    ).goToNextOrPrev(next);
    if (newSelectedDate) {
      if (this.defaultDate) {
        this.defaultDate = newSelectedDate;
      }
      this.onDateChange();
    }
  }

  selectCustomRange(item: ICustomDateRanges): void {
    this.defaultDate = item.ranges;
    this.onDateChange();
  }

  initializeDatePicker() {
    const curLang = this.translate.currentLang;
    this.zone.runOutsideAngular(() => {
      const options: any = {
        locale: this.generateLocale(curLang),
        mode: this.isRangePicker ? 'range' : 'single',
        defaultDate: this.defaultDate,
        maxDate: this.maxDate,
        minDate: this.minDate,
        inline: true,
        prevArrow: '«',
        nextArrow: '»',
        static: true,
        onChange: this.onChange.bind(this),
        showMonths: this.showMonths,
        plugins: this.isMonthSelect
          ? [
              new (monthSelectPlugin as any)({
                shorthand: true,
                dateFormat: 'm.y',
                altFormat: 'F Y',
              }),
            ]
          : [],
      };

      this.datePickerEl = flatpickr(
        this.datepicker.nativeElement,
        options,
      ) as flatpickr.Instance;
    });
    this.myCustomRanges = new DateManipulation(
      this.defaultDate,
    ).getMyCustomRanges(this.customRange);
    this.datePickerEl.setDate(this.defaultDate);
  }

  private generateLocale(language) {
    switch (language) {
      case 'ka':
        return Georgian;
        break;
      case 'ru':
        return Russian;
        break;
      case 'uz':
        return UzbekLatin;
        break;
    }
  }

  private onChange(selectedDate: Date[]): void {
    if (selectedDate[1]) {
      this.defaultDate = [selectedDate[0], endOfDay(selectedDate[1])];
    } else {
      this.defaultDate = selectedDate[0];
    }
    if (!selectedDate[1] && this.isRangePicker) {
      return;
    }
    this.onDateChange();
  }

  get isInfiniteDate() {
    return this.defaultDate[0] && this.defaultDate[0].getFullYear() === 1970;
  }

  // Dynamic Values rangeLimitDays = max days you can select,
  // rangeLimitErrorMessage = error message to show
  private onDateChange(): void {
    if (this.rangeIsLimited) {
      const day: number = differenceInCalendarDays(
        this.defaultDate[1],
        this.defaultDate[0],
      );
      if (day && day + 1 > this.rangeLimitDays) {
        this.notificator.sayError(this.rangeLimitErrorMessage);
        const dateFrom = this.route.snapshot.queryParamMap.get('dateFrom');
        const dateTo = this.route.snapshot.queryParamMap.get('dateTo');
        const defaultDate = [dateFrom, dateTo] ?? this.customRange[0].ranges;
        this.datePickerEl.setDate(defaultDate);
        return;
      }
    }
    this.dateChange.emit(this.defaultDate);
    this.datePickerEl.setDate(this.defaultDate);
    this.isDatePickerVisible = !this.isDatePickerVisible;
    this.cdr.detectChanges();
  }
}
