import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  ClientService,
  RoutingStateService,
  Service,
  StorageService,
} from '@optimo/core';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IData } from '../payment-warning-dialog/payment-warning-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { padString } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';

@Component({
  selector: 'app-payment-methods-dialog',
  templateUrl: './payment-methods-dialog.component.html',
  styleUrls: ['./payment-methods-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private copy$ = new Subject<number>();
  public bankAccountInfo: any;
  public requestIsSent: boolean;
  public paymentMethodInfos = [
    {
      icon: 'cards',
      title: 'GENERAL.PAYMENTS_BILLING.PaymentMethodInfos.CARD.TITLE',
      list: this.translate.instant(
        'GENERAL.PAYMENTS_BILLING.PaymentMethodInfos.CARD.LIST',
      ),
      warning: '',
      hasButton: true,
      listHasBullets: false,
    },
    {
      icon: 'express-pay',
      title: 'GENERAL.PAYMENTS_BILLING.PaymentMethodInfos.EXPRESS_PAY.TITLE',
      list: this.translate.instant(
        'GENERAL.PAYMENTS_BILLING.PaymentMethodInfos.EXPRESS_PAY.LIST',
      ),
      warning: '',
      hasButton: false,
      listHasBullets: false,
    },
    {
      icon: 'bog-building',
      title: 'GENERAL.PAYMENTS_BILLING.PaymentMethodInfos.BOG.TITLE',
      list: this.translate.instant(
        'GENERAL.PAYMENTS_BILLING.PaymentMethodInfos.BOG.LIST',
      ),
      warning: '',
      hasButton: false,
      listHasBullets: true,
    },
  ];
  public showPayButton = false;

  constructor(
    private clipboard: Clipboard,
    private notificator: NotificationsService,
    private dialogRef: MatDialogRef<PaymentMethodsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { cards: IData[]; shouldPay: number; balanceId: number },
    private cdr: ChangeDetectorRef,
    private client: ClientService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private _routingState: RoutingStateService,
    private _domSanitizer: DomSanitizer,
    private storage: StorageService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.showPayButton = !!this.storage.getSecAccessToken();
    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancel();
    });
    this.copy$
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.clipboard.copy(padString(res.toString(), 5, '0'));
        this.notificator.saySuccess(
          'GENERAL.PAYMENTS_BILLING.BALANCE_ID_SUCCESS',
        );
      });
  }
  copyValue(value: number) {
    this.copy$.next(value);
  }
  goBack() {
    this.dialogRef.close(null);
  }
  onAddBankAccount() {
    this.getOnlinePayments();
  }
  private getOnlinePayments() {
    const isUnAuthPay = this.storage.getSecAccessToken();
    this.client
      .post<any>(
        'onlinePayments/card/add',
        {
          successUrl: isUnAuthPay
            ? environment.PAYMENT_UNAUTH_SUCCESS
            : environment.PAYMENT_SUCCESS,
          failUrl: isUnAuthPay
            ? environment.PAYMENT_UNAUTH_FAIL
            : environment.PAYMENT_FAIL,
        },
        { service: Service.Payment },
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.bankAccountInfo = res;
        window.open(res.url, '_self');
        this.cdr.markForCheck();
      });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  get hasBalanceId() {
    return this.data.balanceId !== undefined;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
