<app-column
  [dataField]="col.dataField"
  [caption]="col.caption"
  [col]="col"
  [asyncCaption]="col.asyncCaption"
  [sortable]="col.sortable"
  [widthPercent]="col.widthPercent"
  [isHeaderRight]="!col.sortable"
  (cellClick)="cellClick.emit($event)"
  (currencySwitch)="currencySwitch.emit($event)"
  [isOpen]="isOpen"
>
  <ng-template *ngIf="headerCellTmpl" #headerCell>
    <ng-container *ngTemplateOutlet="headerCellTmpl"></ng-container>
  </ng-template>

  <ng-template #cell let-row="row">
    <ng-container
      *ngTemplateOutlet="
        cellTmpl;
        context: { cell: row[col.dataField], row: row }
      "
    ></ng-container>
    <div
      *ngIf="!cellTmpl" class="text-truncate text-right"
      [matTooltip]="row[col.dataField] | date: format" #ref1 [matTooltipDisabled]="textIsTruncated(ref1)"
    >
      <span>
        {{ row[col.dataField] | date: format}}
      </span>
    </div>
  </ng-template>

  <ng-template *ngIf="col.filterable" #filterCell>
    <div class="d-flex align-items-center h-100" (click)="onToggleFilter($event);  isOpen=!isOpen">
      <input
        data-hj-allow
        type="text"
        class="table-filter-input"
        [placeholder]="'TABLE.SELECT' | translate"
        readonly
        [id]="'table-header-date-picker-'+col.dataField"
        [value]="displayedFilter"
      />
      <app-icon
        [icon]="'calendar'"
        [ngClass]="{'visibility-hidden pointer-events-none' : displayedFilter}"
        class="th-icon cursor-pointer"
      ></app-icon>
    </div>
    <button
      *ngIf="displayedFilter"
      type="reset"
      class="filter-reset outline-none"
      [id]="'table-header-date-picker-filter-reset'"
      (click)="toggleRange(); onSubmit(true)"
    >
      <app-icon icon="filter-reset"></app-icon>
    </button>
    <app-date-month-picker
      *ngIf="col.columnType == columnType.DateMonth"
      [isDatePickerVisible]="isFilterVisible"
      (onIsDatePickerVisible)="$event ? null : onToggleFilter();  isOpen=!isOpen"
      (dateChange)="onMonthChange($event); onSubmit();"
      [defaultDate]="displayedFilter"
      [id]="'table-header-date-month-picker-regular'"
      class="date-picker-regular rangepicker-top-0 only-range-picker"
    >
    </app-date-month-picker>
    <app-date-picker
      *ngIf="col.columnType == columnType.Date"
      [isDatePickerVisible]="isFilterVisible"
      [showActionsAndInput]="false"
      (onIsDatePickerVisible)="$event ? null : onToggleFilter()"
      [maxDate]="col.data?.maxDate"
      [defaultDate]="dateRange"
      (dateChange)="onRangeChange($event); onSubmit()"
      [id]="'table-header-date-picker-regular'"
      class="date-picker-regular rangepicker-top-0 only-range-picker"
    ></app-date-picker>
  </ng-template>
</app-column>
