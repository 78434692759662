<app-column
  [dataField]="col.dataField"
  [caption]="col.caption"
  [col]="col"
  [asyncCaption]="col.asyncCaption"
  [sortable]="col.sortable"
  [widthPercent]="col.widthPercent"
  [className]="col.className"
  (cellClick)="cellClick.emit($event)"
  (currencySwitch)="currencySwitch.emit($event)"
>
  <ng-template *ngIf="headerCellTmpl" #headerCell>
    <ng-container *ngTemplateOutlet="headerCellTmpl"></ng-container>
  </ng-template>

  <ng-template *ngIf="cellTmpl" #cell let-row="row" let-i="i">
    <ng-container
      *ngTemplateOutlet="
        cellTmpl;
        context: { cell: row[col.dataField], row: row, i: i }
      "
    ></ng-container>
  </ng-template>

  <ng-template *ngIf="col.filterable" #filterCell>
    <form [formGroup]="filterForm" class="d-flex align-items-center h-100">
        <input
          data-hj-allow
          type="text"
          class="table-filter-input"
          [placeholder]="'TABLE.FILL_IN' | translate"
          formControlName="filter"
          autocomplete="off"
          [id]="'table-header-input-'+col.dataField"
          (keyup)="onSubmit()"
        />
        <app-icon [icon]="'search'" class="th-icon"></app-icon>
    </form>
  </ng-template>
</app-column>
