import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retryWhen, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StorageService } from '@optimo/core';
import { EErrorCode } from './types';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _bottomSeet: MatBottomSheet,
    private _storage: StorageService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const maxRetryAttempts = 1;
    const excludedStatusCodes = [EErrorCode.MultipleLegalEntitiesException];

    return next.handle(request).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((errors, attemptIndex) => {
            if (
              attemptIndex >= maxRetryAttempts ||
              excludedStatusCodes.find((e) => e === errors.error?.errorCode)
            ) {
              return throwError(errors);
            }
            return timer(1);
          }),
        );
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            if (error.url.includes('RefreshAccessToken')) {
              this.onFailedRefreshToken();
            } else {
              return throwError(error);
            }
            break;

          default:
            return throwError(error);
        }
      }),
    );
  }

  private onFailedRefreshToken(): void {
    this._storage.deleteAccessToken();
    this._dialog.closeAll();
    this._bottomSeet.dismiss();
    this._router.navigate(['/login']);
  }
}
