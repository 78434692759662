import {
  IMixpanelUser,
  IMixpanelUserProps,
} from './../../../../../../libs/mixpanel/src/lib/models/IMixpanelUser';
import { MixpanelService } from './../../../../../../libs/mixpanel/src/lib/services/mixpanel.service';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {
  IdleActions,
  IdlePopupComponent,
} from 'apps/dashboard/src/app/popups/idle-popup/idle-popup.component';
import {
  StorageService,
  Service,
  RoutingStateService,
  EOptimoProductType,
  LocalizationService,
} from '@optimo/core';
import { Router } from '@angular/router';
import { MessagePopupComponent } from 'apps/dashboard/src/app/popups/message-popup/message-popup.component';
import { ClientService, filterNavsByProductType } from '@optimo/core';
import { UserDetails } from 'apps/dashboard/src/app/pages/auth/profile/personal-information/profile-personal-information.component';
import { MENU_TREE } from './sidebar/nested-menu.mock';
import { LocationService } from '../../core/services/location/location.service';
import { PackageType } from 'libs/core/src/lib/models/package-type.enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Providers } from 'apps/dashboard/src/app/core/enums/providers.enum';
import { ConfigService } from 'apps/dashboard/src/app/core/services/config/config.service';
import { environment } from '../../../environments/environment';
import { CookieService } from '../../../../../../libs/core/src/lib/helpers/cookie.service';
import {
  AsyncNotificationsService,
  RsUploadStatus,
} from '../../core/services/notifications/async-notification.service';
import { NotificationsService } from '../../core/services/notifications/notifications.service';
import { NotificationsSignalrService } from '../../core/services/notifications/notifications-signalr.service';
@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizedLayoutComponent implements OnInit, OnDestroy {
  pageLoading: boolean;
  userDetails: UserDetails;
  glovoIntegration: any;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private userIdle: UserIdleService,
    private storage: StorageService,
    private dialog: MatDialog,
    private bottomSeet: MatBottomSheet,
    private router: Router,
    private client: ClientService,
    private _translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private routingState: RoutingStateService,
    private location: LocationService,
    private _mixpanelService: MixpanelService,
    private _configService: ConfigService,
    private cookieService: CookieService,
    private localization: LocalizationService,
    private asyncNotificationsService: AsyncNotificationsService,
    private notificationsSignalrService: NotificationsSignalrService,
    private notificator: NotificationsService,
  ) {
    this.translateMenuAndPageTitles();
    this._translate.onLangChange
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((_event: LangChangeEvent) => {
          this.translateMenuAndPageTitles();
          routingState.resetPageTitle();
        }),
      )
      .subscribe();
  }

  public translateMenuAndPageTitles() {
    const currentProductType = this._configService.getConfig()?.productType;
    this.routingState.updatePageTitle(
      filterNavsByProductType(MENU_TREE, currentProductType).map((menuItem) =>
        this.translateMenuItem(menuItem),
      ),
    );
  }

  private translateMenuItem(menuItem): any {
    const translatedItem = {
      ...menuItem,
      text: this._translate.instant(menuItem.text),
      pageName: this._translate.instant(menuItem.text || menuItem.pageName),
    };
    if (translatedItem.children) {
      translatedItem.children = translatedItem.children.map((childMenuItem) =>
        this.translateMenuItem(childMenuItem),
      );
    }
    return translatedItem;
  }

  ngOnInit(): void {
    this.userIdle.startWatching();
    this.checkLanguage();
    this.userIdle
      .onTimerStart()
      .pipe(
        filter((time) => time === 1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.openIdlePopup());

    this.getUserDetails();

    this.asyncNotificationsService.notifications.subscribe((notification) => {
      if (notification.name === 'sale order notification') {
        if (notification.description.Payload.Status === RsUploadStatus.Success)
          this.notificator.saySuccess('GENERAL.WAYBILL_UPLOAD_SUCCESS');
        if (notification.description.Payload.Status === RsUploadStatus.Failed) {
          // Most common error from RS
          if (notification.description.Payload.AdditionaInfo == '-1061') {
            this.notificator.sayError('ERROR_MESSAGE.CHECK_RS_ERROR');
          } else {
            const message =
              this._translate.instant('GENERAL.WAYBILL_UPLOAD_FAILE') +
              ' ' +
              notification.description.Payload.AdditionaInfo;
            this.notificator.sayError(message);
          }
        }
      }
    });
    this.notificationsSignalrService.connect();
    this.asyncNotificationsService.listenToNotificationSignalR();
  }

  private mixpanelIdentify(
    identificationNumber: string,
    businessTypeName: string,
  ): void {
    const config = this._configService.getConfig();
    const userIdentifier: string = config.userUid;
    const user: IMixpanelUser = {
      legalEntityName: config.legalEntityName,
      packageType: PackageType[+config.packageType],
      productType: EOptimoProductType[+config.productType],
      companyId: identificationNumber,
      isAdapter: config.isAdapter,
      legalEntityStatus: config.legalEntityStatusName,
      legalEntityId: config.legalEntityId,
      businessType: businessTypeName ?? '',
    };
    const userProps: IMixpanelUserProps = {
      fullName: config.fullName,
      isForTesting: config.isForTesting,
      $name: config.fullName,
    };
    ``;
    if (config.isAdmin || (environment.production && config.isForTesting)) {
      this._mixpanelService.disbaleTracking();
    }
    this._mixpanelService.identify(user, userProps, userIdentifier);
  }

  private getUserDetails(): void {
    this.client
      .get('user/getuserdetails', { service: Service.Auth })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userDetails: UserDetails) => {
        this.userDetails = userDetails;
        this.mixpanelIdentify(
          userDetails.identificationNumber,
          userDetails?.businessType[0]?.name ?? '',
        );
        if (userDetails && userDetails.integrationData) {
          userDetails.integrationData.map((item) => {
            if (item && item.provider && item.provider === Providers.Glovo) {
              this.glovoIntegration = item;
              this.cdr.markForCheck();
            }
          });
        }
        this.cdr.markForCheck();
      });
  }

  private openIdlePopup(): void {
    this.dialog
      .open(IdlePopupComponent, {
        width: '575px',
        panelClass: ['mat-w-90pct-u-custsm'],
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((acton: IdleActions) => {
        switch (acton) {
          case IdleActions.Continue:
            this.userIdle.resetTimer();
            break;
          case IdleActions.Logout:
            this.onLogout();
            break;
          case IdleActions.Timeout:
            this.onLogout(false);
            this.openLoggedOutMessagePopup();
            break;
        }
      });
  }

  private openLoggedOutMessagePopup(): void {
    this.dialog
      .open(MessagePopupComponent, {
        width: '575px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.router.navigate(['/login']);
      });
  }

  onLogout(navigateToLogin = true): void {
    this.dialog.closeAll();
    this.bottomSeet.dismiss();
    this.client.get('user/SignOut', { service: Service.Auth }).subscribe();
    this.storage.deleteAccessToken();
    this.storage.resetSpace();
    this.location.unsetLocation();
    try {
      this._mixpanelService.reset();
    } catch (e) {
      console.log('mixpanel reset failed');
    }
    if (navigateToLogin) {
      this.router.navigate(['/login']);
    }
  }

  checkLanguage() {
    const lang = this.cookieService.get('selectedLanguage');
    if (lang) {
      this.localization.switchLanguage(lang, false);
    }
  }

  ngOnDestroy(): void {
    this.userIdle.stopWatching();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.asyncNotificationsService.unsubscribeSignalR();
  }
}
