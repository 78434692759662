import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  Optional,
  Inject,
} from '@angular/core';
import { ColumnData } from '../types';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@optimo/core';

@Component({
  selector: 'app-col-visibility-selector',
  templateUrl: './col-visibility-selector.component.html',
  styleUrls: ['./col-visibility-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColVisibilitySelectorComponent implements OnInit {
  @ViewChild('selectHeader', { static: true })
  selectHeader: ElementRef;

  @Input()
  columns: ColumnData[];

  active: boolean;

  constructor(
    @Optional()
    @Inject('UI_CONFIG')
    public UI_CONFIG: { currencyLabel: string },
    private storage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (!this.UI_CONFIG) {
      this.UI_CONFIG = { currencyLabel: '₾' };
    }
  }

  ngOnInit(): void {
    this.restoreColumnsState();
  }

  onVisibleChange(col: ColumnData): void {
    col.hidden = !col.hidden;

    this.saveColumnsState();
  }

  toggleSelect(target?: any): void {
    if (target && this.selectHeader.nativeElement.contains(target)) {
      return;
    }
    this.active = !this.active;
  }

  get visibilityManagableColumns(): ColumnData[] {
    return this.columns?.filter((value) => !value?.hideInVisibilitySelector);
  }

  private restoreColumnsState(): void {
    const savedColumns: Partial<ColumnData>[] = this.storage.get(
      this.componentName,
      true,
    );

    if (savedColumns) {
      this.columns.forEach((col) => {
        const matchCol = savedColumns.find(
          (c) => c.dataField === col.dataField,
        );
        if (matchCol) {
          col.hidden = matchCol.hidden;
        }
      });
    }
  }

  private saveColumnsState(): void {
    this.storage.set(
      this.componentName,
      this.columns.map((c) => ({
        dataField: c.dataField,
        hidden: c.hidden,
      })),
      true,
    );
  }

  private get componentName(): string {
    return this.router.routerState.snapshot.url.split('/')[1].split('?')[0];
  }
}
