import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeKa from '@angular/common/locales/ka';
import localeRu from '@angular/common/locales/ru';
import localeUz from '@angular/common/locales/uz';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule, LocalizationService, StorageService } from '@optimo/core';
import { LoaderBarModule } from '@optimo/ui-loader-bar';
import { NotifierModule } from 'angular-notifier';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpDefaultParamInterceptor } from './core/services/interceptor/http_default_param_interceptor';
import { AuthorizedLayoutModule } from './layouts/authorized-layout/authorized-layout.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpLoaderFactory } from './core/factories/ngx-translate-factories';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServerErrorInterceptor } from './core/services/error/server-error.interceptor';
import { GlobalErrorHandler } from './core/services/error/global-error-handler';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import {
  currencyFormat,
  currencyLabel,
  currencyFormatShort,
  isUz,
} from '../config/ui-config';
import { PaymentWarningDialogModule } from './pages/payments/payment-warning-dialog/payment-warning-dialog.module';
import { PaymentMethodsDialogModule } from './pages/payments/payment-methods-dialog/payment-methods-dialog.module';
import { PaymentMessageDialogModule } from './pages/payments/payment-message-dialog/payment-message-dialog.module';
import { LimitPopupModule } from './popups/limit-popup/limit-popup.module';
registerLocaleData(localeKa);
registerLocaleData(localeRu);
registerLocaleData(localeUz);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule.forRoot({
      authRoute: environment.AUTH_ROUTE,
      mainRoute: environment.INVENTORY_ROUTE,
      reportingRoute: environment.REPORTING_ROUTE,
      refreshAccessTokenEndpoint: 'User/RefreshAccessToken',
      isProduction: environment.production,
      gtagId: environment.GTAG_ID,
      appType: environment.APP_TYPE,
      catalogueRoute: environment.CATALOGUE_ROUTE,
      posRoute: environment.POS_ROUTE,
      integrationsRoute: environment.INTEGRATIONS_ROUTE,
      billingRoute: environment.BILLING_ROUTE,
      purchaseRoute: environment.PURCHASE_ROUTE,
      adminRoute: environment.ADMIN_ROUTE,
      paymentRoute: environment.PAYMENT_URL,
    }),
    AppRoutingModule,
    LimitPopupModule,
    PaymentWarningDialogModule,
    PaymentMethodsDialogModule,
    PaymentMessageDialogModule,
    AuthorizedLayoutModule,
    BrowserAnimationsModule,
    NotifierModule.withConfig({
      behaviour: {
        autoHide: 7000,
        showDismissButton: true,
      },
      position: {
        horizontal: {
          position: 'left',
          distance: 24,
        },
        vertical: {
          position: 'bottom',
          distance: 24,
          gap: 16,
        },
      },
    }),
    HttpClientModule,
    LoaderBarModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: isUz ? 'uz' : 'ka',
    }),
  ],
  providers: [
    {
      provide: 'UI_CONFIG',
      useValue: { currencyLabel, currencyFormat, currencyFormatShort, isUz },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpDefaultParamInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (localizationService: LocalizationService) =>
        localizationService.currentLanguage,
      deps: [LocalizationService, TranslateService, StorageService],
    },

    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 0,
        position: 'above',
        disableTooltipInteractivity: true,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
