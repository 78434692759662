import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  currencyFormat,
  currencyFormatShort,
} from 'apps/dashboard/src/config/ui-config';

@Pipe({
  name: 'currencyPipe',
})
export class OptimoCurrencyPipe implements PipeTransform {
  transform(value: number, shortFormatting = false) {
    return formatNumber(
      value,
      'en',
      shortFormatting ? currencyFormatShort : currencyFormat,
    );
  }
}
