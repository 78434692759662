import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ClientService,
  RoutingStateService,
  Service,
  StorageService,
} from '@optimo/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-payment-message-dialog',
  templateUrl: './payment-message-dialog.component.html',
  styleUrls: ['./payment-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMessageDialogComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  public loading: boolean;
  constructor(
    private storage: StorageService,
    private notificator: NotificationsService,
    private dialogRef: MatDialogRef<PaymentMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      success: boolean;
      hasCloseBtn: boolean;
      btnText: string;
    },
    private cdr: ChangeDetectorRef,
    private client: ClientService,
    private dialog: MatDialog,
    private _router: Router,
    private fb: UntypedFormBuilder,
    private _routingState: RoutingStateService,
    private _domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancel();
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.loading = true;
    const accessToken = this.storage.getSecAccessToken();
    const decodedAccessToken = decode(accessToken);
    if (this.data.hasCloseBtn) {
      if (this.data.success) {
        const sseSource = new EventSource(
          `https://identity.dev.optimo.ge/v1/legalentities/${decodedAccessToken['LegalEntityId']}/sse/`,
        );

        sseSource.addEventListener('status', (event) => {
          if (event.data == 0) {
            // success
            this.loading = false;
            this.dialogRef.close(true);
            this.refreshToken();
            sseSource.close();
          }
        });
        sseSource.onerror = function (_) {
          //fail
          sseSource.close();
        };
      } else {
        this._router
          .navigate(['login'], {
            queryParams: { showBankAccountModal: true },
          })
          .then(() => {
            this.loading = false;
            this.dialogRef.close(false);
          });
      }
    } else {
      this.loading = false;
      this.dialogRef.close(false);
    }
  }

  private refreshToken() {
    this.storage.setCheckingRefreshToken(true);
    this.client
      .get<any>('user/signinwithtoken', { service: Service.Auth })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.storage.setAccessToken(res.accessToken);
        this.storage.deleteSecAccessToken();
        this.dialogRef.close(false);
        this._router.navigate(['/']);
      });
  }
}
