<app-column
  [dataField]="col.dataField"
  [caption]="col.caption"
  [col]="col"
  [asyncCaption]="col.asyncCaption"
  [sortable]="col.sortable"
  [widthPercent]="col.widthPercent"
  [isHeaderRight]="data.isHeaderRight"
  (cellClick)="cellClick.emit($event)"
  [isOpen]="isOpen"
  (currencySwitch)="currencySwitch.emit($event)"
>
  <ng-template *ngIf="headerCellTmpl" #headerCell>
    <ng-container *ngTemplateOutlet="headerCellTmpl"></ng-container>
  </ng-template>

  <ng-template #cell let-row="row">
    <ng-container
      *ngTemplateOutlet="
        cellTmpl;
        context: { cell: row[col.dataField], row: row }
      "
    ></ng-container>

      <div *ngIf="!cellTmpl" class="d-flex align-items-center justify-content-end text-truncate font-color-dark mw-100">
       <ng-container *ngIf="!hasQuantity">
          <span
            class="text-truncate"
            [ngClass]="classNameArr(row)"
            [matTooltip]="getShownNumber(row)"
            #ref1 [matTooltipDisabled]="textIsTruncated(ref1)"
          >{{ getShownNumber(row) }}
          </span>
       </ng-container>

       <ng-container *ngIf="hasQuantity">
        <span
          [ngClass]="classNameArr(row)"
          [matTooltip]="getCell(row)"
          #ref7 [matTooltipDisabled]="textIsTruncated(ref7)"
          class="text-truncate"
        >
           {{getCell(row)}}
        </span>
        <div class="umo-container">
          {{ getUnit(row) | translate }}
        </div>
       </ng-container>

      </div>
  </ng-template>

  <ng-template *ngIf="col.filterable" #filterCell>
    <div class="d-flex align-items-center h-100" (click)="onToggleFilter($event);  isOpen=!isOpen">
      <input
        data-hj-allow
        type="text"
        class="table-filter-input"
        [placeholder]="'TABLE.SELECT' | translate"
        readonly
        [id]="'table-header-number-input-'+col.dataField"
        [value]="displayedFilter"
      />
      <app-icon
        [icon]="'arrow-down'"
        [ngClass]="{'visibility-hidden pointer-events-none' : displayedFilter}"
        class="th-icon cursor-pointer"
      ></app-icon>
    </div>
    <button
      *ngIf="displayedFilter"
      type="reset"
      class="filter-reset"
      [id]="'table-header-'+col.dataField+'-filter-reset'"
      (click)="filterForm.reset(); onSubmit(true)"
    >
    <app-icon icon="filter-reset"></app-icon>
    </button>
    <app-number-range-picker
      *ngIf="isFilterVisible"
      clickOutside
      (clickOutside)="onToggleFilter(); isOpen = !isOpen"
      [filterForm]="filterForm"
      [lowStock]="col.minStock"
      (submit)="onSubmit()"
      (reset)="onSubmit(true)"
    ></app-number-range-picker>
  </ng-template>
</app-column>
