import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService, StorageService } from '@optimo/core';
import { Location } from '@angular/common';
import { arrayOflanguages } from '../../../../config/ui-config';

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard {
  constructor(
    private router: Router,
    private storage: StorageService,
    private location: Location,
    private translationService: LocalizationService,
  ) {}

  canActivate() {
    const lang = this.location.path().split('/');
    if (lang[2] && arrayOflanguages.includes(lang[2])) {
      this.translationService.switchLanguage(lang[2], false);
    }
    const accessToken = this.storage.getAccessToken();
    if (!accessToken) {
      return true;
    }
    this.router.navigate(['/']).then((_) => {
      this.translationService.switchLanguage(lang[2], false);
    });
    return false;
  }
}
