import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@optimo/core';
import { CookieService } from 'libs/core/src/lib/helpers/cookie.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    public router: Router,
    private storage: StorageService,
    private cookieService: CookieService,
  ) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    const lang = this.cookieService.get('selectedLanguage');
    if (lang) {
      this.cookieService.set('selectedLanguage', lang);
    }
    return this.isAuthed;
  }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthed;
  }

  private get isAuthed(): boolean {
    const accessToken = this.storage.getAccessToken();
    if (!accessToken) {
      this.goToLogin();
      return false;
    }

    return true;
  }

  private goToLogin() {
    this.router.navigate(['login']);
  }
}
