<ng-container
  [cdkColumnDef]="dataField"
  [sticky]="sticky"
  [stickyEnd]="stickyEnd"
>
  <th
    cdk-header-cell
    *cdkHeaderCellDef
    mat-sort-header
    [disabled]="!sortable"
    [ngClass]="{
      active: sort?.active === dataField,
      asc: sort?.active === dataField && sort?.direction === 'asc',
      desc: sort?.active === dataField && sort?.direction === 'desc',
      hidden: hidden,
      'position-right': isHeaderRight,
      'table-currency-column-th': hasCurrencyLabel
    }"
    [style.width.%]="widthPercent"
  >
    <ng-container *ngTemplateOutlet="headerCellTmpl"></ng-container>
    <ng-container *ngIf="!headerCellTmpl">
      <span class="d-flex">
        <span
          #ref1 [matTooltipDisabled]="innerPageCurrencyLabel ? false : textIsTruncated(ref1)"
          [matTooltip]="(caption | translate : {currencyLabel: UI_CONFIG.currencyLabel}) + getCurrencyLabel"
        >
          {{ caption | translate : {currencyLabel: UI_CONFIG.currencyLabel} }}
        </span>
        <div
          class="ml-1 currency-label"
          *ngIf="hasCurrencyLabel"
          [ngClass]="{
        'currency-label-under-110px-d-none uzs': UI_CONFIG.isUz,
        'currency-label-under-100px-d-none gel': !UI_CONFIG.isUz
       }">
          {{getCurrencyLabel}}
        </div>
      </span>
      <div class="th-icons-sorting" *ngIf="sortable">
        <app-icon *ngIf="sortable" [icon]="'sort-up'" class="mb-2px"></app-icon>
        <app-icon *ngIf="sortable" [icon]="'sort-down'" class="mt-2px"></app-icon>
      </div>
      <div
        class="d-flex ml-auto"
        [matTooltipDisabled]="textIsTruncated(ref1)"
        [matTooltip]="(caption | translate : {currencyLabel: UI_CONFIG.currencyLabel}) + getCurrencyLabel"
        *ngIf="col?.hasCurrencySwitcher"
      >
        <div class="nav-tabs nav-tabs-two mini-tab">
          <ng-container *ngFor="let tab of numberColumnTypes; let i=index;">
            <button
              [class.w-40px]="UI_CONFIG.isUz && i==0"
              class="nav-item"
              [class.active]="activeIndex.toString() === tab.toString()"
              (click)="toggleTab(tab, $event)"
            >
              <ng-container *ngIf="tab.toString() === NumberColumnType.Currency.toString()">
                {{UI_CONFIG.currencyLabel}} <span class="dublicated-text">{{UI_CONFIG.currencyLabel}}</span>
              </ng-container>
              <ng-container *ngIf="tab.toString() === NumberColumnType.Percent.toString()">
                % <span class="dublicated-text">%</span>
              </ng-container>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </th>
  <td cdk-cell *cdkCellDef="let row; let i = index" [class.hidden]="hidden">
    <ng-container
      *ngTemplateOutlet="cellTmpl; context: { row: row, i: i }"
    ></ng-container>
    <div *ngIf="!cellTmpl" class="td-content-wrapper d-flex line-h-1-4">
      <span
		[matTooltip]="row[dataField]"
		#ref2 [matTooltipDisabled]="textIsTruncated(ref2)"
        [ngClass]="className"
        class="font-family-regular font-color-dark"
      >
        {{ row[dataField] }}
      </span>
    </div>
  </td>
</ng-container>

<ng-container [cdkColumnDef]="dataField + '-filter'">
  <th
    cdk-header-cell
    *cdkHeaderCellDef
    [style.width.%]="widthPercent"
    [class.hidden]="hidden"
    [ngClass]="{opened: isOpen}"
  >
    <ng-container *ngTemplateOutlet="filterCellTmpl"></ng-container>
  </th>
</ng-container>
