import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

export interface LoadingData {
  description: string;
  title: string;
  package_url: string;
  icon: boolean;
}

@Component({
  selector: 'app-limit-popup',
  templateUrl: './limit-popup.component.html',
  styleUrls: ['./limit-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LimitPopupComponent {
  constructor(
    protected translate: TranslateService,
    private dialogRef: MatDialogRef<LimitPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadingData,
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  get generatePackagesUrl() {
    return `${environment.LANDING_ROUTE}${this.translate.currentLang}/${this.data.package_url}`;
  }
}
