import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { CdkColumnDef, CdkTable } from '@angular/cdk/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { textIsTruncated } from '../utils/text-is-truncated';
import { NumberColumnType } from '@optimo/ui-table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnComponent implements OnInit {
  public textIsTruncated = textIsTruncated;
  NumberColumnType = NumberColumnType;
  private _isOpen = false;
  @ViewChildren(CdkColumnDef)
  columnDefs: QueryList<CdkColumnDef>;

  @ContentChild('headerCell')
  headerCellTmpl: TemplateRef<any>;

  @ContentChild('cell')
  cellTmpl: TemplateRef<any>;

  @ContentChild('filterCell')
  filterCellTmpl: TemplateRef<any>;

  @Output()
  currencySwitch = new EventEmitter<NumberColumnType>();

  @Input()
  widthPercent: number;

  @Input()
  dataField: string;

  @Input()
  caption: string;

  @Input()
  col: any;

  @Input()
  asyncCaption: Observable<string>;

  @Input()
  sortable = true;

  @Input()
  sticky: boolean;

  @Input() set isOpen(value) {
    this._isOpen = value;
  }
  get isOpen() {
    return this._isOpen;
  }

  @Input()
  stickyEnd: boolean;

  @Input()
  hidden: boolean;

  @Input()
  isHeaderRight: boolean;

  @Input()
  className: string;

  activeIndex = NumberColumnType.Currency;
  numberColumnTypes = [NumberColumnType.Currency, NumberColumnType.Percent];
  constructor(
    @Optional()
    @Inject('UI_CONFIG')
    public UI_CONFIG: { currencyLabel: string; isUz: boolean },
    @Optional() private table: CdkTable<any>,
    @Optional() public sort: MatSort,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    if (!this.UI_CONFIG) {
      this.UI_CONFIG = { currencyLabel: '₾', isUz: false };
    }
  }

  ngOnInit(): void {
    if (this.table) {
      this.cdr.detectChanges();
      this.columnDefs.forEach((col) => {
        this.table.addColumnDef(col);
      });
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params) {
        const switcherType = params[this.col?.switcherName];
        this.activeIndex = switcherType
          ? switcherType
          : NumberColumnType.Currency;
      }
    });
  }

  toggleTab(tab, event): void {
    event.stopPropagation();
    let queryParams = this.activatedRoute.snapshot.queryParams;
    const isPercentType = tab === NumberColumnType.Percent;
    const switcherType = isPercentType ? NumberColumnType.Percent : null;
    queryParams = {
      ...queryParams,
      ...this.generateQueryParams(isPercentType),
    };

    if (this.activeIndex !== tab) {
      this.activeIndex = tab;
      switch (tab) {
        case NumberColumnType.Percent:
          this.currencySwitch.emit(NumberColumnType.Percent);
          break;
        case NumberColumnType.Currency:
          this.currencySwitch.emit(NumberColumnType.Currency);
          break;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { ...queryParams, [this.col.switcherName]: switcherType },
      });
    }
  }

  private generateQueryParams(isPercentType) {
    if (isPercentType) {
      return {
        marginAmountFrom: null,
        marginAmountTo: null,
        totalIncomeTo: null,
        totalIncomeFrom: null,
      };
    } else {
      return {
        marginRateFrom: null,
        marginRateTo: null,
        totalIncomeRateTo: null,
        totalIncomeRateFrom: null,
      };
    }
  }

  get isCurrency() {
    if (this.col?.data !== undefined) {
      const value = this.col.data['type'];
      return value ? +value === NumberColumnType.Currency : false;
    }
    return false;
  }
  get hasCurrencyLabel() {
    return (
      this.isCurrency &&
      !this.col?.hasCurrencySwitcher &&
      (this.col?.hasCurrencyLabel || this.col?.filterable)
    );
  }
  get getCurrencyLabel() {
    return this.isCurrency ? ' (' + this.UI_CONFIG.currencyLabel + ')' : '';
  }
  get innerPageCurrencyLabel() {
    return !this.col?.filterable && this.isCurrency;
  }
}
