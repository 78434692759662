import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ClientService, Service, StorageService } from '@optimo/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationsService } from '../../../../../../../admin-panel/src/app/core/services/notifications/notifications.service';
import { PaymentMessageDialogComponent } from '../../payment-message-dialog/payment-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ApproveDialogComponent } from '@optimo/ui-popups-approve-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-methods-card',
  templateUrl: './payment-methods-card.component.html',
  styleUrls: ['./payment-methods-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsCardComponent {
  @Input() cards;
  @Input() shouldPay;
  @Input() hasPayBtn = false;
  @Input()
  data: {
    icon: string;
    title: string;
    list: string[];
    listHasBullets: boolean;
    warning: string;
    hasButton: boolean;
  };
  @Output() onAddCard = new EventEmitter();
  @Output() onPay = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  private unsubscribe$ = new Subject<void>();
  public loading: boolean;
  constructor(
    private _cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    private notificator: NotificationsService,
    private _router: Router,
    private client: ClientService,
    private storage: StorageService,
    public translate: TranslateService,
  ) {}

  onAddBankAccount() {
    this.dialog
      .open(ApproveDialogComponent, {
        width: '575px',
        data: {
          title: this.translate.instant(
            'GENERAL.PAYMENTS_BILLING.ApproveDialog.titleForAdd',
          ),
          message: this.translate.instant(
            'GENERAL.PAYMENTS_BILLING.ApproveDialog.messageForAdd',
          ),
          approveBtnLabel: 'GENERAL.CONTINUE',
          denyBtnLabel: 'GENERAL.CANCEL',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.onAddCard.emit();
          this.loading = true;
        }
      });
  }

  onEditCard() {
    this.dialog
      .open(ApproveDialogComponent, {
        width: '575px',
        data: {
          title: this.translate.instant(
            'GENERAL.PAYMENTS_BILLING.ApproveDialog.titleForChange',
          ),
          message: this.translate.instant(
            'GENERAL.PAYMENTS_BILLING.ApproveDialog.messageForChange',
          ),
          approveBtnLabel: 'GENERAL.CONTINUE',
          denyBtnLabel: 'GENERAL.CANCEL',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.editCard();
        }
      });
  }

  onDeleteCard() {
    this.dialog
      .open(ApproveDialogComponent, {
        width: '575px',
        data: {
          title: this.translate.instant(
            'GENERAL.PAYMENTS_BILLING.ApproveDialog.titleForDelete',
          ),
          message: this.translate.instant(
            'GENERAL.PAYMENTS_BILLING.ApproveDialog.messageForDelete',
          ),
          approveBtnLabel: 'GENERAL.CONTINUE',
          denyBtnLabel: 'GENERAL.CANCEL',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.deleteCard();
        }
      });
  }

  private editCard() {
    const isUnAuthPay = this.storage.getSecAccessToken();
    this.client
      .post<any>(
        'onlinePayments/card/add',
        {
          successUrl: isUnAuthPay
            ? environment.PAYMENT_UNAUTH_SUCCESS
            : environment.PAYMENT_SUCCESS,
          failUrl: isUnAuthPay
            ? environment.PAYMENT_UNAUTH_FAIL
            : environment.PAYMENT_FAIL,
        },
        { service: Service.Payment },
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        window.open(res.url, '_self');
        this._cdr.markForCheck();
      });
  }

  private deleteCard() {
    this.client
      .delete<any>(
        'cards',
        { cardId: this.cards[0].id },
        { service: Service.Payment },
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.onDelete.next(true);
        this._router
          .navigate(['/login'], {
            queryParams: { success_delete: true },
          })
          .then((_) => {
            this.cards = [];
          });
        this._cdr.markForCheck();
      });
  }

  onPayDebt() {
    if (this.loading) {
      return;
    }
    const dueAmount = this.shouldPay;
    this.loading = true;
    this.client
      .post<any>(
        'onlinePayments/card',
        {
          amount: dueAmount,
        },
        { service: Service.Payment },
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (_) => {
          this.loading = false;
          this.onPay.next(true);
          this.showPaymentMessage({
            title: this.translate.instant(
              'GENERAL.PAYMENTS_BILLING.PAYMENT_SUCCESS',
            ),
            message: this.translate.instant(
              'GENERAL.PAYMENTS_BILLING.PAYMENT_SUCCESS_MESSAGE',
            ),
            success: true,
            hasCloseBtn: true,
            btnText: this.translate.instant('GENERAL.PAYMENTS_BILLING.SUBMIT'),
          });
        },
        (_) => {
          this.loading = false;
          this.onPay.next(true);
          this.showPaymentMessage({
            title: this.translate.instant(
              'GENERAL.PAYMENTS_BILLING.PAYMENT_FAIL',
            ),
            message: this.translate.instant(
              'GENERAL.PAYMENTS_BILLING.PAYMENT_FAIL_MESSAGE',
            ),
            success: false,
            hasCloseBtn: true,
            btnText: this.translate.instant('GENERAL.PAYMENTS_BILLING.BACK'),
          });
        },
      );
  }

  private showPaymentMessage(data) {
    this._router.navigate(['login'], {
      queryParams: {},
    });
    this.dialog
      .open(PaymentMessageDialogComponent, {
        width: '480px',
        panelClass: 'overflow-visible',
        disableClose: true,
        data: data,
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        // console.log(result);
      });
  }

  get hasCard() {
    return this.cards !== undefined && !!this.cards.length;
  }

  get isMC() {
    return (
      this.hasCard &&
      (+this.cards[0].maskedCard[0] === 5 || +this.cards[0].maskedCard[0] === 2)
    );
  }

  get isVISA() {
    return this.hasCard && +this.cards[0].maskedCard[0] === 4;
  }

  get maskedCard() {
    const str: any = this.cards[0].maskedCard;
    const asterisks = str.replace(/[a-zA-Z0-9]/g, '*').slice(0, -1);
    const mask = asterisks.match(/.{1,4}/g);
    return `${mask.join(' ')} ${str.slice(-4)}`;
  }
}
